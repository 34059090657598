import { useContext } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import GeneralReportInfo from "../ReportParts/GeneralReportInfo";
import PageFooter from "../ReportParts/PageFooter";
import PageHeader from "../ReportParts/PageHeader";
import SEAandDMCalculations from "../ReportParts/SEAandDMCalculations";
import WaterQualityMetricCalculations from "../ReportParts/WaterQualityMetricCalculations";
import { ReportContext } from "../../context/ReportContext";
import Page from "./PageTemplate";
import "./print.css";

const WaterSampleReportMain = () => {
  const { report } = useContext(ReportContext);

  return report ? (
    <Page>
      <Flex direction="column" justify="space-between" h="100%">
        <VStack spacing={4} align="stretch">
          <Box>
            <PageHeader
              sampleNumber={report?.reportId}
              pageTitle={"WATER QUALITY REPORT"}
            />
            <GeneralReportInfo />

            <WaterQualityMetricCalculations />

            <SEAandDMCalculations />
          </Box>
        </VStack>
        {/* <PageFooter page={1} /> */}
      </Flex>
      <PageFooter />
    </Page>
  ) : (
    <Box>Loading report...</Box>
  );
};

export default WaterSampleReportMain;
