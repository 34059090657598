import React, { useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import { ReportContext } from "../../context/ReportContext";

import Page from "./PageTemplate";
import PageFooter from "../ReportParts/PageFooter";

const ReportInformation = () => {
  const { report, contentChunks } = useContext(ReportContext);

  const formatContent = (content: string) => {
    const boldText = content.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    const formattedText = boldText.replace(/\n/g, "<br>");
    const indentedText = formattedText.replace(
      /\t/g,
      "&nbsp;&nbsp;&nbsp;&nbsp;"
    );
    return { __html: indentedText };
  };

  return report && contentChunks ? (
    <Page>
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        sx={{
          pageBreakBefore: "always",
          "@media print": {
            pageBreakInside: "avoid",
            pb: "50px",
          },
        }}
      >
        <Box w="100%" mb={"20%"}>
          {contentChunks.map((word, i) => (
            <Box key={i}>
              <Text variant="sectionTitle">{word.header}</Text>
              <Text
                variant="sectionBody"
                dangerouslySetInnerHTML={formatContent(word.content)}
              />
            </Box>
          ))}
        </Box>
      </Flex>
      <Box
        sx={{
          "@media print": {
            display: "none",
          },
        }}
      >
        <PageFooter />
      </Box>
    </Page>
  ) : null;
};

export default ReportInformation;
