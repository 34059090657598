import { Box, Flex, Text } from "@chakra-ui/react";
import sliderMarker from "../../assets/images/wqi-slider-marker.svg";
import { Range } from "../../constants/constants";
import { getTextColorBasedOnBgColor } from "../../services/colorService";
import { formatMetric } from "../../services/sample-calculation/calculationService";
import { getColorBasedOnValueFromRange } from "../../services/rangeService";

type WQRSliderProps = {
  sliderRanges: Range[];
  indexValue: number | undefined;
};

const WQRSlider = ({ sliderRanges, indexValue }: WQRSliderProps) => {
  let numericValue = indexValue ?? 0;

  const bgColor = getColorBasedOnValueFromRange(sliderRanges, numericValue);
  const fgColor = getTextColorBasedOnBgColor(bgColor);

  // using reduce, get the min and max values of the slider ranges
  const rangeMin = sliderRanges
    .map((r) => r.min)
    .reduce((min, val) => {
      return val < min ? val : min;
    });

  const rangeMax = sliderRanges
    .map((r) => r.max)
    .reduce((max, val) => {
      return val > max ? val : max;
    });

  const widthOfMarker = 80;

  const getSliderMarkerCalculation = () => {
    const pct = (numericValue / rangeMax) * 100;
    return `calc(${pct}% - ${widthOfMarker / 2}px)`;
  };

  const sliderMarkerCalculation = getSliderMarkerCalculation();

  return (
    <Box
      className="wqr-slider"
      mt="8px"
      mb="16px"
      w="100%"
      height={"100px"}
      pos={"relative"}
      sx={{
        "@media screen and (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <Box position={"relative"}>
        <Box
          position={"absolute"}
          top={"2px"}
          left={`${sliderMarkerCalculation}`} // 13px is half the width of the slider marker
          zIndex={10}
          textAlign={"center"}
        >
          <Box
            bg={bgColor}
            color={fgColor}
            fontWeight="bold"
            fontSize="16px"
            width={`${widthOfMarker}px`}
            textAlign="center"
            borderRadius="5px"
            border={"1px solid var(--chakra-colors-gray-800)"}
          >
            {formatMetric(numericValue)}
          </Box>
          <img
            src={sliderMarker}
            style={{ margin: "0 auto", position: "relative", top: "-5px" }}
            alt="Slider Marker"
          />
        </Box>
      </Box>
      <Flex
        className="Butter"
        pos={"absolute"}
        width={"100%"}
        top={"38px"}
        borderRadius="5px"
        border={"1px solid var(--chakra-colors-gray-800)"}
        style={{ contain: "paint" }}
      >
        {sliderRanges.map((ele, idx) => {
          let percentage = ((ele.max - ele.min) / (rangeMax - rangeMin)) * 100;
          let fgColor = getTextColorBasedOnBgColor(ele.color);

          const isFirst = idx === 0;
          const isLast = idx === sliderRanges.length - 1;

          const rangeFontSize =
            percentage <= 5 ? "8px" : percentage <= 8 ? "11.5px" : "13.5px";
          const labelFontSize =
            percentage <= 5 ? "10px" : percentage <= 8 ? "12px" : "14.5px";

          return (
            <Box
              key={idx}
              h="56px"
              w={percentage + "%"}
              color={fgColor}
              bg={ele.color}
              py={"0px"}
              px={isFirst || isLast ? "8px" : "3px"}
              fontSize={labelFontSize}
              // if it is the first element, align left, if it is the last element, align right, else center
              textAlign={isFirst ? "left" : isLast ? "right" : "center"}
              display={"flex"}
              borderLeft={
                isFirst ? "" : "1px solid var(--chakra-colors-gray-800)"
              }
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Text fontWeight="600">{ele.name}</Text>
              <Text fontSize={rangeFontSize} fontWeight="500">
                {ele.min}-{ele.max}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default WQRSlider;
