interface ToastProps {
  isDesktop?: boolean;
  toast: any;
  title: string;
  description: string;
  status: "info" | "warning" | "success" | "error" | "loading" | undefined;
}

const ShowToast = (props: ToastProps) => {
  const showToast = () => {
    props.toast({
      position: props.isDesktop ? "bottom" : "top",
      title: props.title,
      description: props.description,
      status: props.status,
      duration: 3000,
      isClosable: true,
    });
  };

  showToast();
};

export default ShowToast;
