import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import {
  StreamIndexRanges,
  WaterQualityIndexRanges,
} from "../../constants/constants";
import * as rangeService from "../../services/rangeService";
import HowItWorks from "./HowItWorks";
import {
  formatMetric,
  isAutotrophicCalc,
  isFallWinterCalc,
  isFPOMInTransportCalc,
  hasStableSubstrateCalc,
  isPredatorPreyHealthyCalc,
  SDICategoryCalc,
  SDInumeratorCalc,
} from "../../services/sample-calculation/calculationService";
import { OrganismList } from "../../models/OrganismListCount";

const EARData = () => {
  const { report } = useContext(ReportContext);
  let data = report;

  // from the json data?.FamilyGroupsJson, get the list of organisms in the sample, json parse
  let organismList: OrganismList = [];
  if (data?.familyGroupsJson !== undefined) {
    organismList = JSON.parse(data.familyGroupsJson);
  }
  let organismCount: number = 0;
  if (data?.organismsCount !== undefined) {
    organismCount = data?.organismsCount;
  }

  const simpsonsIndexNumerator = SDInumeratorCalc(organismList, organismCount);

  return (
    <Box>
      <HowItWorks
        label={rangeService.getRangeNameBasedOnValueFromRange(
          StreamIndexRanges,
          data?.streamConditionIndex
        )}
        value={formatMetric(data?.streamConditionIndex)}
        valueLabel={"Stream Condition Index"}
        formula={
          "(Total Taxa + EPT Taxa + Hilsenhoff Biotic Index + % Tolerant + % EPT Abundance + % Dominance) / 6"
        }
        scale={
          "Poor: 0-50 | Marginal: 50-65<br/>Suboptimal: 65-75 | Optimal: 75-100"
        }
      >
        {`(${formatMetric(data?.totalTaxaScore)} + ${formatMetric(
          data?.eptTaxaScore
        )} + ${formatMetric(data?.hilsenhoffBioticIndexScore)} + ${formatMetric(
          data?.tolerantScore
        )} + ${formatMetric(data?.eptAbundanceScore)} + ${formatMetric(
          data?.dominanceScore
        )}) / 6 = ${formatMetric(data?.streamConditionIndex)}`}
      </HowItWorks>

      <HowItWorks
        label={rangeService.getWQIRangeNameBasedOnValue(
          WaterQualityIndexRanges,
          data?.waterQualityIndex
        )}
        value={formatMetric(data?.waterQualityIndex)}
        valueLabel={"Water Quality Index"}
        formula={"Total FTV / Total Organisms"}
        scale={
          "Excellent: 0-3.75 | Very Good: 3.75-4.25<br/>Good: 4.25-5 | Fair: 5-5.75<br/>Fairly Poor: 5.75-6.5 | Poor: 6.5-7.25<br/>Very Poor: 7.25-10"
        }
      >
        {`${data?.totalFTV} / ${data?.organismsCount} = ${formatMetric(
          data?.waterQualityIndex
        )}`}
      </HowItWorks>

      <HowItWorks
        label={
          isAutotrophicCalc(data?.prIndex) ? "Autotrophic" : "Heterotrophic"
        }
        value={formatMetric(data?.prIndex)}
        valueLabel={"A/H Index"}
        formula={"Scrapers / (Shredders + G. Collectors + F.Collectors)"}
        scale={"Hetrotrophic &lt;= 0.75<br/>Autotrophic &gt; 0.75"}
      >
        {data?.scrapersCount} / ({data?.shreddersCount} +{" "}
        {data?.gCollectorsCount} + {data?.fCollectorsCount}) ={" "}
        {formatMetric(data?.prIndex)}
      </HowItWorks>

      <HowItWorks
        label={
          isFallWinterCalc(data?.cpomFpomIndex)
            ? "Fall-Winter Shredder Stream"
            : "Spring-Summer Shredder Stream"
        }
        value={formatMetric(data?.cpomFpomIndex)}
        valueLabel="CPOM/FPOM Index"
        formula="Shredders / (G. Collectors + F. Collectors)"
        scale="Spring-Summer Shredder Stream &lt;= 0.50<br/>Fall-Winter Shredder Stream &gt; 0.50"
      >
        {data?.shreddersCount} / ({data?.gCollectorsCount} +{" "}
        {data?.fCollectorsCount}) = {formatMetric(data?.cpomFpomIndex)}
      </HowItWorks>

      <HowItWorks
        label={
          isFPOMInTransportCalc(data?.tfpomFpomIndex)
            ? "FPOM in Transport"
            : "FPOM in Storage"
        }
        value={formatMetric(data?.tfpomFpomIndex)}
        valueLabel="TFPOM/BFPOM Index"
        formula="F. Collectors / G. Collectors"
        scale="FPOM in Transport &gt; 0.50<br/>FPOM in Storage &lt; 0.50"
      >
        {data?.fCollectorsCount} / {data?.gCollectorsCount} ={" "}
        {formatMetric(data?.tfpomFpomIndex)}
      </HowItWorks>

      <HowItWorks
        label={
          hasStableSubstrateCalc(data?.channelStabilityIndex)
            ? "Stable Substrate"
            : "Unstable Substrate"
        }
        value={formatMetric(data?.channelStabilityIndex)}
        valueLabel="Channel Stability Index"
        formula="(Scrapers + F. Collectors) / (Shredders + G.Collectors)"
        scale="Stable Substrate &gt; 0.75<br/>Unstable Substrate &lt; 0.75"
      >
        ({data?.scrapersCount} + {data?.fCollectorsCount}) / (
        {data?.shreddersCount} + {data?.gCollectorsCount}) ={" "}
        {formatMetric(data?.channelStabilityIndex)}
      </HowItWorks>

      <HowItWorks
        label={
          isPredatorPreyHealthyCalc(data?.topDownPredatorControlIndex)
            ? "Healthy Balance"
            : "Unhealthy Balance"
        }
        value={formatMetric(data?.topDownPredatorControlIndex)}
        valueLabel="Top-down Predator Control (Predator/Prey ratio)"
        formula="Predators / (Scrapers + Shredders + F. Collectors + G. Collectors)"
        scale="Unhealthy Balance < 0.1<br/>
        Healthy Balance: 0.1-0.2<br/>
        Unhealthy Balance > 0.2"
      >
        {data?.predatorsCount} / ({data?.scrapersCount} + {data?.shreddersCount}{" "}
        + {data?.fCollectorsCount} + {data?.gCollectorsCount}) ={" "}
        {formatMetric(data?.topDownPredatorControlIndex)}
      </HowItWorks>
      <HowItWorks
        label={SDICategoryCalc(data?.sdi)}
        value={formatMetric(data?.sdi)}
        valueLabel="Simpson's Diversity Index"
        formula="(Σ n(n-1) / N(N-1))"
        scale="Near Specialization: >0.65<br/>
        Low diversity: 0.55-0.65<br/>
        Medium diversity: 0.45-0.55<br/>
        High Diversity: <0.45"
      >
        N = [Total Number of organisms of ALL species] = {organismCount}
        <br />n = [Total Number of organisms of ONE species]
        <br />Σ n(n-1) = {simpsonsIndexNumerator}
        <br />
        {`(${simpsonsIndexNumerator} / (${organismCount}(${organismCount}-1)) = ${formatMetric(
          data?.sdi
        )}`}
      </HowItWorks>
    </Box>
  );
};
export default EARData;
