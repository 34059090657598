export const Text = {
    baseStyle: {
        color: "primary.black.brand",
        fontFamily: "font.body", 
        fontWeight: "normal", 
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        sectionTitle: {
            fontFamily: "heading",
            fontWeight: "700",
            fontSize: "25px",
            lineHeight: "30px",
            letterSpacing: "5%",
            align: "left",
            verticalAlign: "top",
            color: "primary.blue.brand",
        },
        sectionBody: {
            fontSize: "15px",
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: "font.body",
        }, 
        standOut: {
            fontSize: "15px",
            fontWeight: "700",
            color: "primary.blue.brand",
        },
        submissionSectionTitle: { 
            fontSize:"21px",
            fontWeight:"700",
            fontFamily:"font.heading",
            lineHeight: "30px",
            letterSpacing: "0.42px",
            color: "primary.blue.brand", 
        },
        optionCard: { 
            color: "primary.blue.brand", 
            fontSize: "16px",     
            fontWeight:"700",
            fontFamily: "font.body",
            lineHeight: "25px"         
        },
        navLinks: { 
            color: "primary.gray.brand",
            textAlign: "right",
            fontSize: "21px", 
            fontFamily: "font.heading",
            fontWeight: "600", 
            lineHeight: "30px", 
            letterSpacing: "1.05px", 
            textTransform: "uppercase", 
            _hover: { color:"primary.blue.100"},   
        },
        paragraph: { 
            color: "gray_neutral.800", 
            textAlign: "left", 
            lineHeight: "35px"
        }, 
        breadcrumbLink: { 
            fontFamily:"font.body", 
            fontWeight:"semibold", 
            color:"primary.blue.600", 
            fontSize:"16px",
            lineHeight:"25px"
        }, 
        secondarylabel: { 
            lineHeight:"25px",
            fontWeight:"400" ,
            color:"gray_neutral.800"
        }
    },
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
        size: '',
        variant: '',
        colorScheme: '',
    },
}