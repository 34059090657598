export const FormLabel = {
    baseStyle: {
        color: "primary.blue.brand",
        fontSize: "16px", 
        fontFamily: "Work Sans", 
        fontWeight: "600",
        lineHeight: "25px", 
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: { 
        nowrap: { 
            textWrap: "nowrap"
        }
    }, 
    // default values for 'size', 'variant' and 'colorScheme'
    defaultProps: {
        size: '',
        variant: '',
        colorScheme: '',
    },
}