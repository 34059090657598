import React from "react";
import {
  Box,
  HStack,
  Text,
  VStack,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BlueDottedLine } from "../../icons/Icons";

interface HowItWorksProps {
  label: string;
  value: string | number | undefined;
  valueLabel: string;
  formula: string;
  scale: string;
  children: React.ReactNode;
}

const HowItWorks = ({
  label,
  value,
  valueLabel,
  formula,
  scale,
  children,
}: HowItWorksProps) => {
  const formattedValue = value ?? "";

  return (
    <div>
      <HStack
        gap="0"
        className="Gap"
        fontFamily="body"
        justifyContent="center"
        color="primary.blue.brand"
        textAlign="center"
        justify="center"
      >
        <VStack spacing="0" w="45%" fontSize="15px" textAlign={"left"}>
          <Text fontWeight="700" width={"100%"}>
            {formula}
          </Text>
          <Text width={"100%"}>{children}</Text>
        </VStack>

        <VStack spacing="0" w="25%">
          <Text textAlign="center" fontSize="24px" fontWeight="700">
            {formattedValue}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="600"
            style={{ whiteSpace: "break-spaces" }}
          >
            {valueLabel}
          </Text>
        </VStack>

        <VStack w="30%" spacing="0" textAlign="right">
          <Text width={"100%"} fontWeight="700" fontSize="16px">
            {label}
          </Text>
          <Text
            width={"100%"}
            fontSize="12px"
            fontStyle="italic"
            dangerouslySetInnerHTML={{ __html: scale }}
          ></Text>
        </VStack>
      </HStack>
      <Box my="12px">
        <Icon w="100%" as={BlueDottedLine} />
      </Box>
    </div>
  );
};

export default HowItWorks;
