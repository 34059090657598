import { Flex, Box } from "@chakra-ui/react";
import FormHeading from "./Components/FormHeading";
import EstimateOptions from "./EstimateOptions";
import Sliders from './Sliders';

export default function StreamEnvInfo() {
    return (
        <>
            <Box id="streamEnvInfo">
                <FormHeading title="STREAM ENVIRONMENT INFORMATION"></FormHeading>
            </Box>
            <Flex
                justify="space-between"
                gap="50px"
                flexDirection={{
                    lg: "row",
                    base: "column",
                    md: "column",
                    sm: "column"
                }}>
                <Sliders />
                <EstimateOptions />
            </Flex>
        </>
    )
}