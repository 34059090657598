import {
    Box,
    Flex,
    FormLabel,
    Select,
    Button,
    Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { format } from 'date-fns'
import TextInput from "./Components/TextInput";
import Map from "./Components/Map";
import FormHeading from "./Components/FormHeading";

export default function SampleInformation() {
    const { setValue, register, resetField, formState: { errors } } = useFormContext();
    const [showMap, setShowMap] = useState(false);
    const greatLakeOptions = [
        "Lake Michigan",
        "Lake Superior",
        "Lake Huron",
        "Lake Erie",
        "Lake Ontario",
        "Other",
    ];

    const handleMapChange = (string: string) => {
        resetField("Coordinates"); 
        setValue("Coordinates", string);
    };

    const handleSetShowMap = () => {
        setShowMap(showMap ? false : true);
    }

    const handleSetToday = () => {
        resetField("SampleDate"); 
        setValue("SampleDate", format(new Date(), "yyyy-MM-dd"));
    }

    return (
        <>
            <FormHeading title="SAMPLE INFORMATION"></FormHeading>
            <Flex dir="row" align="flex-end" gap="sm" justify="flex-start" maxW="max-content">
                <TextInput
                    label="Date Sampling Completed:"
                    value="SampleDate"
                    formType="date"
                    isRequired
                />
                <Button variant="toggle" pb={!!errors["SampleDate"] ? "24px" : "0px"} onClick={handleSetToday}>Today</Button>
            </Flex>

            <Flex dir="row" align="flex-end" gap="sm" maxW="max-content">
                <TextInput
                    label="GPS Coordinates of Study Site:"
                    value="Coordinates"
                    formType="text"
                    isRequired={true}
                />
                <Button variant="toggle" pb={!!errors["Coordinates"] ? "24px" : "0px"} onClick={handleSetShowMap}>{showMap ? "Hide" : "Show"} Map</Button>
            </Flex>
            {showMap && <Box w="100%">
                <Map getCoordinates={handleMapChange} />
            </Box>}

            <TextInput
                label="Stream Name"
                value="StreamName"
                formType="text"
                isShort
                isRequired
            />

            <Box>
                <Flex flexDirection={{
                    lg: "row",
                    md: "column",
                    base: "column",
                    sm: "column"
                }}>
                    <FormLabel>Which one of the Great Lake drainage basins</FormLabel>
                    <Text variant="secondarylabel">(i.e watershed) are you sampling in?</Text>
                </Flex>
                <Select
                    fontFamily="font.body"
                    fontSize="18px"
                    borderColor="gray_cool.400"
                    placeholder="Select a Lake"
                    {...register("GreatLakesBasin", { required: false })}
                >
                    {greatLakeOptions.map((lake, i) => (
                        <option key={i} value={lake}>
                            {lake}
                        </option>
                    ))}
                </Select>
            </Box>

            <TextInput
                formType="text"
                label="Name of smaller immediate drainage basin that your stream is a part of:"
                secondarylabel="(optional, if you do not know, please leave blank)"
                value="SmallDrainageBasin"
                isRequired={false}
            />
            <TextInput
                formType="text"
                label="Name of larger drainage basin that your stream is a part of:"
                secondarylabel="(i.e. what major waterway will your water eventually get to)"
                value="LargeDrainageBasin"
                isRequired={false}
            />
            <TextInput
                formType="text"
                label="Calculated Stream Discharge:"
                secondarylabel="(m3/sec)"
                value="StreamDischarge"
                isShort
                isRequired={false}
            />
        </>
    )
}