import { Box, Text, Icon } from "@chakra-ui/react";
import { Nav } from "./Nav";
import { SmallBlueBar } from "../../icons/Icons";

interface Props {
  title: string;
}

export const Header = ({ title }: Props) => {
  return (
    <Box
      color="brand.100"
      as="section"
      bgImage="linear-gradient(0deg, rgba(0, 48, 120, 0.75), rgba(0, 48, 120, 0.75)), url('/images/river-with-rocks.jpg')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      bgSize="cover"
      id="header"
    >
      <Nav />
      <Box maxW="1440px" m="auto">
        <Box pb="40px" ml="40px" mr="40px">
          <Text
            color="white"
            fontSize="60px"
            fontFamily="font.heading"
            fontWeight="700"
            lineHeight={{lg:"95px", base: "60px"}}
            pb={{base: "20px", lg: "0px"}}
            letterSpacing="3px"
            textTransform="uppercase"
            textAlign="left"
          >
            {title}
          </Text>
          <Icon as={SmallBlueBar} />
        </Box>
      </Box>
    </Box>
  );
};
