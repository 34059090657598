import {
  Box,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Flex,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import { formatMetric } from "../../services/sample-calculation/calculationService";
import {} from "../../services/rangeService";
import { WQMetricCalculationsTables } from "./WQMetricCalculationsTables";
import {
  StreamIndexRanges,
  WaterQualityIndexRanges,
} from "../../constants/constants";
import RangeIndexDisplay from "./RangeIndexDisplay";
import { css } from "@emotion/react";

const WaterQualityMetricCalculations = () => {
  const { report } = useContext(ReportContext);
  const data = report;

  const streamConditionIndex = data?.streamConditionIndex;
  const waterQualityIndex = data?.waterQualityIndex;
 
  return (
    <Box mt="15px">
      <Text variant="sectionTitle">WATER QUALITY METRICS</Text>
      <br></br>

      <RangeIndexDisplay
        title={"Stream Condition Index"}
        range={StreamIndexRanges}
        value={streamConditionIndex}
      />

      <RangeIndexDisplay
        title={"Water Quality Index"}
        range={WaterQualityIndexRanges}
        value={waterQualityIndex}
      />

      <Flex
        alignItems="baseline"
        flexDirection={{ base: "column", md: "row" }}
        gap="60px"
        mt="10px"
        css={css`
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }

          @media print {
            flex-direction: row;
          }
        `}
      >
        <WQMetricCalculationsTables />

        <Box w="100%" h="100%">
          <TableContainer
            borderRadius="8px 8px 0 0"
            border="1px"
            borderColor="gray_cool.300"
          >
            <Table variant="report">
              <Thead>
                <Tr>
                  <Td>ADDITIONAL METRICS</Td>
                  <Td></Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Ephemeroptera Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.ephemeropteraTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Plecoptera Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.plecopteraTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Trichoptera Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.trichopteraTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Long-lived Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.longLivedTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Odonata Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.odonataTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Diptera Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.dipteraTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>COET Taxa</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {data?.coetTaxa}
                  </Td>
                </Tr>
                <Tr>
                  <Td>% Sensitive</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {formatMetric(data?.sensitivePercent)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>% Chironomidae</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {formatMetric(data?.chironomidaePercent)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>% Clingers</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {formatMetric(data?.clingersPercent)}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Simpson Diversity Index</Td>
                  <Td fontWeight="bold" textAlign="right">
                    {formatMetric(data?.sdi)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </Box>
  );
};
export default WaterQualityMetricCalculations;
