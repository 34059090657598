import {
  Text,
  Image,
  Card,
  CardBody,
  Box,
  CardFooter,
  Flex,
} from "@chakra-ui/react";
import React from "react";

interface optionCardProps {
  text: string;
  link?: string;
  image: string;
  handleCardClick?: any;
  checkbox?: any;
  isChecked?: boolean;
}

function OptionCard({
  text,
  link,
  image,
  handleCardClick,
  checkbox,
  isChecked,
}: optionCardProps) {
  return (
    <Card
      {...(checkbox ? { ...checkbox } : null)}
      border="solid"
      cursor="pointer"
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      borderColor="gray_neutral.100"
      bg={isChecked ? "primary.blue.700" : "white"}
      _hover={{ boxShadow: "md" }}
      onClick={() => {
        handleCardClick && handleCardClick(link);
      }}
    >
      <Flex direction="column" justify="center" align="center">
        <CardBody>
          <Box>
            <Image
              src={image}
              alt="placeholder"
              height={{ lg: "105px", base: "60px" }}
            />
          </Box>
        </CardBody>
        <CardFooter>
          <Text
            variant="optionCard"
            color={isChecked ? "white" : "primary.blue.900"}
          >
            {text}
          </Text>
        </CardFooter>
      </Flex>
    </Card>
  );
}

export default OptionCard;
