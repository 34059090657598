import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { Header } from "../Layout/Header";
import { useContext, useEffect, useState } from "react";
import { SampleList } from "./SampleList";
import { ApiContext } from "../../context/ApiContext";

export const HomePage = () => {
  const { homeSamples } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 30000);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <>
      <Header title="EXPLORE YOUR WORLD" />
      <Box maxW="1440px" m="50px auto">
        <Flex flexDirection="column" gap="40px" ml="40px" mr="40px">
          <Text variant="paragraph" fontSize="16px">
            The RAIL Project (Riparian Area Integrated Learning) brings
            hands-on, inquiry-based activity into your classroom through the
            study of aquatic macroinvertebrates. This allows your students to
            gain valuable insights into concepts and processes that occur within
            the mysterious world of a stream ecosystem.
          </Text>
          <Flex
            w={{ base: "min-content", sm: "full" }}
            alignSelf={{ base: "center" }}
            gap={{ base: "md", lg: "" }}
            justify="space-between"
            flexDirection={{ sm: "row", base: "column" }}
          >
            <Button variant="primary">
              <a href="EnterSample">Enter Your Sample</a>
            </Button>
            <Button variant="primaryInversed">
              <a href="Reports">All Reports</a>
            </Button>
          </Flex>
          <Heading alignSelf="center" color="primary.blue.brand">
            RECENT REPORTS
          </Heading>
          <Box w="full">
            {homeSamples.length > 0 ? (
              <SampleList samples={homeSamples} />
            ) : isLoading ? (
              <Stack>
                <Text>Loading reports...</Text>
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
                <Skeleton height="60px" />
              </Stack>
            ) : (
              <Flex justifyContent="center">
                <Text>No Samples Currently</Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
    </>
  );
};
