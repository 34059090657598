import { Range, WaterQualityIndexRanges } from "../constants/constants";

// the 2 functions below are pretty much the same. Create a new function where we can pass in the function that we want to use to get the value
// and then we can use that function to get the value and then use the value to get the color or the name
const getRangePropertyBasedOnValueFromRange = (
  ranges: Range[],
  value: number | undefined,
  defaultValue: string = "unknown",
  property: "description" | "color" | "name"= "name"
): string => {



  // review the ranges and return the color that matches the value that is in between the range
  if (value !== undefined) {
    // run through the ranges in reverse
    for (let i = ranges.length - 1; i >= 0; i--) {
      if (value >= ranges[i].min && value <= ranges[i].max) {

        return ranges[i][property];
      }
    }
  }


  return defaultValue;
};

export const getColorBasedOnValueFromRange = (
  ranges: Range[],
  value: number | undefined,
  defaultValue: string = "#000000"
): string => {

  return getRangePropertyBasedOnValueFromRange(
    ranges,
    value,
    defaultValue,
    "color"
  );
};

export const getRangeNameBasedOnValueFromRange = (
  ranges: Range[],
  value: number | undefined,
  defaultValue: string = "unknown"
): string => {
  return getRangePropertyBasedOnValueFromRange(
    ranges,
    value,
    defaultValue,
    "name"
  );
};

export const getWQIRangeColorBasedOnValue = (value: number | undefined): string => {
  return getColorBasedOnValueFromRange(WaterQualityIndexRanges,value);
};

export const getWQIRangeNameBasedOnValue = (
  ranges: Range[],
  value: number | undefined,
  defaultValue: string = "unknown"
): string => {
  return getRangePropertyBasedOnValueFromRange(
    ranges,
    value,
    defaultValue,
    "name"
  );
}

export const getWQIRangeDescriptionBasedOnValue = (
  ranges: Range[],
  value: number | undefined,
  defaultValue: string = "unknown"
): string => {
  return getRangePropertyBasedOnValueFromRange(
    ranges,
    value,
    defaultValue,
    "description"
  );
}
