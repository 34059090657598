import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  Tr,
  Tbody,
  Td,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Thead,
  useBreakpointValue,
  Text,
  UnorderedList,
  ListItem,
  Flex,
  Box,
  Button,
  Icon,
} from "@chakra-ui/react";
import FormHeading from "../Components/FormHeading";
import DeleteButton from "../Components/DeleteButton";
import { useFormContext } from "react-hook-form";
import KeyInputDesktop from "./KeyInputDesktop";
import KeyInputMobile from "./KeyInputMobile";
import { addIcon } from "../../../icons/Icons";
import ShowToast from "../ShowToast";

interface organism {
  name: string;
  count: string;
  recordId: string;
}

const handleScroll = (scrollRef: string): void => {
  const element = document.getElementById(scrollRef);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const KeyInput = () => {
  const { setValue } = useFormContext();
  const [organism, setOrganism] = useState("");
  const [organismNumber, setOrganismNumber] = useState("");
  const [organismRecordId, setOrganismRecordId] = useState("");
  const [sampleOrganisms, setSampleOrganisms] = useState<organism[]>([]);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const toast = useToast();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    const handleSampleOrganismsChange = (sampleOrganisms: organism[]) => {
      var organisms = sampleOrganisms.map((organism) => {
        return {
          familyGroupId: organism.recordId,
          count: organism.count,
        };
      });
      setValue("FamilyGroupsJson", organisms);
    };
    handleSampleOrganismsChange(sampleOrganisms);
  }, [sampleOrganisms, setValue]);

  const handleOrganismCountChangeAfterAdded = (
    valueAsString: string,
    index: number
  ) => {
    let cleanedValue = valueAsString.replace(/\D/g, "");
    if (cleanedValue === "") {
      cleanedValue = "0";
    }

    const updatedOrganisms = [...sampleOrganisms];
    updatedOrganisms[index].count = cleanedValue;
    setSampleOrganisms(updatedOrganisms);
  };

  const handleAddOrganism = () => {
    let newThing = {
      name: organism,
      count: organismNumber,
      recordId: organismRecordId,
    };

    if (sampleOrganisms.some((o) => o.recordId === newThing.recordId)) {
      ShowToast({
        isDesktop: isDesktop,
        toast: toast,
        title: "Duplicate Organism",
        description: "This organism has already been added.",
        status: "warning",
      });
    } else {
      handleScroll("bottomOfTable");
      ShowToast({
        isDesktop: isDesktop,
        toast: toast,
        title: `${organism} was added successfully`,
        description: "This organism has been added to the table.",
        status: "success",
      });
      setSampleOrganisms([...sampleOrganisms, newThing]);
      setOrganism("");
      setOrganismNumber("");
    }
  };

  const handleDelete = (i: number) => {
    const deletVal = [...sampleOrganisms];
    deletVal.splice(i, 1);
    setSampleOrganisms(deletVal);
  };

  return (
    <>
      <Box id="addOrganisms">
        <FormHeading title="ADD ORGANISMS"></FormHeading>
      </Box>
      {isDesktop ? (
        <KeyInputDesktop
          setOrganism={setOrganism}
          setOrganismNumber={setOrganismNumber}
          handleAddOrganism={handleAddOrganism}
          setOrganismRecordId={setOrganismRecordId}
          organism={organism}
        />
      ) : (
        <KeyInputMobile
          setOrganism={setOrganism}
          setOrganismNumber={setOrganismNumber}
          handleAddOrganism={handleAddOrganism}
          setOrganismRecordId={setOrganismRecordId}
          organism={organism}
        />
      )}
      {!!sampleOrganisms.length && (
        <Box mt="40px">
          {isDesktop ? (
            <TableContainer>
              <Table variant="organisms">
                <Thead>
                  <Tr
                    outline="1px solid"
                    outlineColor="secondary.blue_accent.brand"
                  >
                    <Td w="690px" textTransform="uppercase">
                      Organism
                    </Td>
                    <Td textTransform="uppercase">Count</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {sampleOrganisms.map((organism, i) => (
                    <Tr key={i} h={{ lg: "75px" }}>
                      <Td fontWeight="500">
                        <Text p={{ base: "7px 0 7px 7px" }}>
                          {organism.name}
                        </Text>
                      </Td>
                      <Td
                        onMouseEnter={() => setHoveredRow(i)}
                        onMouseLeave={() => setHoveredRow(null)}
                      >
                        <NumberInput
                          min={1}
                          w="75px"
                          fontFamily="font.body"
                          value={organism.count}
                          onChange={(e) =>
                            handleOrganismCountChangeAfterAdded(e, i)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        >
                          <NumberInputField
                            border={hoveredRow === i ? "solid 1px" : "none"}
                            bg={hoveredRow === i ? "white" : "none"}
                          />
                          {hoveredRow === i && (
                            <NumberInputStepper>
                              <NumberIncrementStepper color="grey" />
                              <NumberDecrementStepper color="grey" />
                            </NumberInputStepper>
                          )}
                        </NumberInput>
                      </Td>
                      <Td textAlign="end">
                        <DeleteButton handleDelete={handleDelete} i={i} />
                      </Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td>
                      <IconButton />
                    </Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Box borderBottom="1px" borderColor="secondary.blue_accent.brand">
                <Text
                  variant="optionCard"
                  fontSize="16px"
                  textTransform="uppercase"
                  m=" 0 0 10px 10px"
                >
                  Organisms
                </Text>
              </Box>
              <UnorderedList listStyleType="none" marginInlineStart="0">
                {sampleOrganisms.map((organism, i) => (
                  <ListItem
                    key={i}
                    bg={i % 2 === 0 ? "" : "secondary.blue_accent.50"}
                  >
                    <Flex
                      flexDir="column"
                      gap="10px"
                      align="flex-start"
                      p="10px"
                    >
                      <Text>{organism.name}</Text>
                      <Flex flexDir="row" justify="space-between" w="full">
                        <NumberInput
                          min={1}
                          w="75px"
                          fontFamily="font.body"
                          value={organism.count}
                          onChange={(e) =>
                            handleOrganismCountChangeAfterAdded(e, i)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          }}
                        >
                          <NumberInputField border="solid 1px grey" />
                          <NumberInputStepper>
                            <NumberIncrementStepper color="grey" />
                            <NumberDecrementStepper color="grey" />
                          </NumberInputStepper>
                        </NumberInput>
                        <DeleteButton handleDelete={handleDelete} i={i} />
                      </Flex>
                    </Flex>
                  </ListItem>
                ))}
                <ListItem>
                  <IconButton />
                </ListItem>
              </UnorderedList>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default KeyInput;

const IconButton = () => {
  return (
    <Button p="10px" onClick={() => handleScroll("addOrganisms")}>
      <Icon as={addIcon} />
      <Text variant="optionCard" pl="10px">
        Add another organism
      </Text>
    </Button>
  );
};
