import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Flex,
  Text
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

interface Props {
  label: string;
  dbValue: string;
  options: any[];
}

const RadioButton: React.FC<Props> = ({
  label,
  dbValue,
  options,
}) => {
  
  const { setValue } = useFormContext(); 
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioButtonChange = (value: string, dbValue: string) => {
    setSelectedValue(value);
    setValue(dbValue, value);
  };

  return (
    <>
      <FormControl>
        <FormLabel htmlFor={dbValue} textAlign="left">
          {label}
        </FormLabel>
        <RadioGroup
          value={selectedValue}
          onChange={(value: string) => handleRadioButtonChange(value, dbValue)}
        >
          <Flex
            gap="sm"
            flexDirection={{ lg: "row", base: "column", sm: "column" }}
            justifyContent="left"
          >
            {options.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
              >
                <Text 
                  color="gray_neutral.800" 
                  fontSize="16px" 
                  fontFamily="font.body" 
                  lineHeight="25px">
                  {option.label}
                </Text>
              </Radio>
            ))}
          </Flex>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioButton;
