import { CalculationData } from "../../models/CalculationData"
import { OrganismList } from "../../models/OrganismListCount"
import { FamilyGroupsData, OrdersData, SpecialAttributesData, FfgData } from "../../models/ContextParsedDataStructure"
import {
    taxaCalc,
    organismsCountCalc,
    totalFTVCalc,
    totalTaxaCalc,
    ePTTaxa,
    hilsenhoffBioticIndexCalc,
    ePTAbundancePercent,
    dominancePercentCalc,
    scoreCalc,
    longLivedTaxaCalc,
    chronomidaePercent,
    fFGCountCalc,
    channelStabilityIndexCalc,
    cpomFpomIndexCalc,
    tfpomFpomIndexCalc,
    topDownPredatorControlIndexCalc,
    pRIndexCalc,
    isAutotrophicCalc,
    isFallWinterCalc,
    isFPOMInTransportCalc,
    hasStableSubstrateCalc,
    isPredatorPreyHealthyCalc,
    isShortLifeCycleCalc,
    waterQualityIndexCalc,
    streamConditionIndexCalc,
    SimpsonsDiversityIndexCalc,
    percentCalc,
    SDInumeratorCalc,
    SDIdenominatorCalc, 
} from "./calculationService"
import { getAttributeByCommonName, getAttributeById } from "../getRecordId"

export const sampleCalculation = async (
    sampleData: OrganismList,
    familyGroupData: { [key: string]: FamilyGroupsData },
    orderData: { [key: string]: OrdersData },
    fFGData: { [key: string]: FfgData },
    specialAttributeData: { [key: string]: SpecialAttributesData }
) => {

    let answer: CalculationData = {
        TotalTaxa: 0,
        EPTTaxa: 0,
        HilsenhoffBioticIndex: 0,
        TolerantPercent: 0,
        EPTAbundancePercent: 0,
        DominancePercent: 0,
        NetSpinningTrichopteraPercent: 0,

        TotalTaxaScore: 0,
        EPTTaxaScore: 0,
        HilsenhoffBioticIndexScore: 0,
        TolerantScore: 0,
        EPTAbundanceScore: 0,
        DominanceScore: 0,
        NetSpinningTrichopteraScore: 0,

        EphemeropteraTaxa: 0,
        PlecopteraTaxa: 0,
        TrichopteraTaxa: 0,
        LongLivedTaxa: 0,
        OdonataTaxa: 0,
        DipteraTaxa: 0,
        COETTaxa: 0,
        SensitivePercent: 0,
        ChironomidaePercent: 0,
        ClingersPercent: 0,

        ShreddersCount: 0,
        ScrapersCount: 0,
        FCollectorsCount: 0,
        GCollectorsCount: 0,
        PredatorsCount: 0,

        PRIndex: 0,
        CpomFpomIndex: 0,
        TfpomFpomIndex: 0,
        ChannelStabilityIndex: 0,
        TopDownPredatorControlIndex: 0,

        isAutotrophic: false,
        isFallWinter: false,
        isFPOMInTransport: false,
        hasStableSubstrate: false,
        isPredatorPreyHealthy: false,
        isShortLifeCycle: false,

        TotalFTV: 0,
        OrganismsCount: 0,
        WaterQualityIndex: 0,

        StreamConditionIndex: 0,

        SDI: 0,
        SDInumerator: 0,
        SDIdenominator: 0,
    }

    answer.OrganismsCount = organismsCountCalc(sampleData)
    answer.TotalFTV = totalFTVCalc(sampleData, familyGroupData)
    answer.EphemeropteraTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Ephemeroptera"])
    answer.PlecopteraTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Plecoptera"])
    answer.TrichopteraTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Trichoptera"])
    answer.OdonataTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Odonata"])
    answer.DipteraTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Diptera"])
    answer.COETTaxa = taxaCalc(sampleData, familyGroupData, orderData, ["Coleoptera", "Odonata", "Ephemeroptera", "Trichoptera"])
    answer.TotalTaxa = totalTaxaCalc(sampleData)
    answer.EPTTaxa = ePTTaxa(answer.EphemeropteraTaxa, answer.PlecopteraTaxa, answer.TrichopteraTaxa)
    answer.HilsenhoffBioticIndex = hilsenhoffBioticIndexCalc(answer.TotalFTV, answer.OrganismsCount)

    answer.TolerantPercent = percentCalc(sampleData, getAttributeById(specialAttributeData, "Tolerant"), answer.OrganismsCount, specialAttributeData)
    answer.NetSpinningTrichopteraPercent = percentCalc(sampleData, getAttributeById(specialAttributeData, "Net-spinners"), answer.OrganismsCount, specialAttributeData)
    answer.SensitivePercent = percentCalc(sampleData, getAttributeById(specialAttributeData, "Sensitive"), answer.OrganismsCount, specialAttributeData)
    answer.ClingersPercent = percentCalc(sampleData, getAttributeById(specialAttributeData, "Clinger"), answer.OrganismsCount, specialAttributeData)
    
    answer.EPTAbundancePercent = ePTAbundancePercent(sampleData, familyGroupData, orderData, answer.OrganismsCount, ["Ephemeroptera", "Plecoptera", "Trichoptera"])
    answer.DominancePercent = dominancePercentCalc(sampleData, answer.OrganismsCount)
    answer.TotalTaxaScore = scoreCalc(answer.TotalTaxa, 22)
    answer.EPTTaxaScore = scoreCalc(answer.EPTTaxa, 13)
    answer.HilsenhoffBioticIndexScore = scoreCalc((10 - answer.HilsenhoffBioticIndex), 7)
    answer.TolerantScore = scoreCalc((100 - answer.TolerantPercent), 98)
    answer.EPTAbundanceScore = scoreCalc(answer.EPTAbundancePercent, 90)
    answer.DominanceScore = scoreCalc(answer.DominancePercent, 90)
    answer.NetSpinningTrichopteraScore = scoreCalc((100 - answer.NetSpinningTrichopteraPercent), 80)
    answer.LongLivedTaxa = longLivedTaxaCalc(sampleData, getAttributeById(specialAttributeData, "Long-lived"), specialAttributeData)
    answer.ChironomidaePercent = chronomidaePercent(sampleData, getAttributeByCommonName(familyGroupData, "Non-biting Midge Fly"), answer.OrganismsCount)

    answer.ScrapersCount = fFGCountCalc(sampleData, getAttributeById(fFGData, "Scraper"), familyGroupData)
    answer.ShreddersCount = fFGCountCalc(sampleData, getAttributeById(fFGData, "Shredder"), familyGroupData)
    answer.FCollectorsCount = fFGCountCalc(sampleData, getAttributeById(fFGData, "Filtering Collector"), familyGroupData)
    answer.GCollectorsCount = fFGCountCalc(sampleData, getAttributeById(fFGData, "Gathering Collector"), familyGroupData)
    answer.PredatorsCount = fFGCountCalc(sampleData, getAttributeById(fFGData, "Predator"), familyGroupData)

    answer.PRIndex = pRIndexCalc(answer.ScrapersCount, answer.ShreddersCount, answer.FCollectorsCount, answer.GCollectorsCount)
    answer.CpomFpomIndex = cpomFpomIndexCalc(answer.ShreddersCount, answer.FCollectorsCount, answer.GCollectorsCount)
    answer.TfpomFpomIndex = tfpomFpomIndexCalc(answer.FCollectorsCount, answer.GCollectorsCount)
    answer.ChannelStabilityIndex = channelStabilityIndexCalc(answer.ScrapersCount, answer.ShreddersCount, answer.FCollectorsCount, answer.GCollectorsCount)
    answer.TopDownPredatorControlIndex = topDownPredatorControlIndexCalc(answer.ScrapersCount, answer.ShreddersCount, answer.FCollectorsCount, answer.GCollectorsCount, answer.PredatorsCount)

    answer.isAutotrophic = isAutotrophicCalc(answer.PRIndex)
    answer.isFallWinter = isFallWinterCalc(answer.CpomFpomIndex)
    answer.isFPOMInTransport = isFPOMInTransportCalc(answer.TfpomFpomIndex)
    answer.hasStableSubstrate= hasStableSubstrateCalc(answer.ChannelStabilityIndex)
    answer.isPredatorPreyHealthy = isPredatorPreyHealthyCalc(answer.TopDownPredatorControlIndex)
    answer.isShortLifeCycle = isShortLifeCycleCalc(answer.TopDownPredatorControlIndex)
    answer.WaterQualityIndex = waterQualityIndexCalc(answer.TotalFTV, answer.OrganismsCount)
    answer.StreamConditionIndex = streamConditionIndexCalc(answer.TotalTaxaScore, answer.EPTTaxaScore, answer.HilsenhoffBioticIndexScore, answer.TolerantScore, answer.EPTAbundanceScore, answer.DominanceScore)
    answer.SDI = SimpsonsDiversityIndexCalc(sampleData, answer.OrganismsCount)
    answer.SDInumerator = SDInumeratorCalc(sampleData, answer.OrganismsCount)
    answer.SDIdenominator = SDIdenominatorCalc(sampleData, answer.OrganismsCount)

    return answer
}