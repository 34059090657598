import {
  ChakraProvider,
  Flex
} from "@chakra-ui/react"
import { HomePage } from "./components/HomePage/HomePage"
import { Footer } from "./components/Layout/Footer"
import '@fontsource/work-sans/400.css'
import '@fontsource/barlow-condensed/400.css'
import '@fontsource/barlow-condensed/600.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import SubmissionForm from "./components/SampleSubmissionPage/SubmissionForm"
import { theme } from "./theme"
import ContactPage from "./components/ContactPage/ContactPage"
import { useEffect, useContext } from "react"
import { ApiContext } from "./context/ApiContext"
import {
  fetchFamilyGroupsTable,
  fetchOrdersTable,
  fetchFFGTable,
  fetchSpecialAttributeTable,
  fetchMacroinvertebratesKeyTable, 
  fetchHomeSamplesTable
} from "./services/fetchAllData"

import { ReportProvider } from "./context/ReportContext"
import AllSamplesPage  from "./components/AllSamplesPage/AllSamplesPage"
import RecalculatePage from "./components/RecalculatePage/RecalculatePage"

export const App = () => {
  const {
    setFamilyGroupsData,
    setOrdersData,
    setFFGData,
    setSpecialAttributeData,
    setMacroinvertebratesKeyData,
    setHomeSamples
  } = useContext(ApiContext)
  
  useEffect(() => {
    (async () => {
      const familyGroupsData = await fetchFamilyGroupsTable();
      const ordersData = await fetchOrdersTable();
      const fFGData = await fetchFFGTable();
      const specialAttributeData = await fetchSpecialAttributeTable();
      const macroinvertebratesKeyData = await fetchMacroinvertebratesKeyTable();
      const homeSamples = await fetchHomeSamplesTable();
      setFamilyGroupsData(familyGroupsData);
      setOrdersData(ordersData)
      setFFGData(fFGData)
      setSpecialAttributeData(specialAttributeData)
      setMacroinvertebratesKeyData(macroinvertebratesKeyData)
      setHomeSamples(homeSamples)
    })();
  }, [setFFGData, setFamilyGroupsData, setMacroinvertebratesKeyData, setOrdersData, setSpecialAttributeData, setHomeSamples]);

  return (
    <Flex direction="column" justify="space-between" minHeight="100vh">
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Reports" element={<AllSamplesPage/>} />
            <Route path="/report/:id" element={<ReportProvider />} />
            <Route path="/EnterSample" element={<SubmissionForm />} />
            <Route path="/Contact" element={<ContactPage />} />
            <Route path="/Secret" element={<RecalculatePage />} />
          </Routes>
          <Footer />
        </ChakraProvider>
      </BrowserRouter>
    </Flex>
  );
};

