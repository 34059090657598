import { Box, VStack } from "@chakra-ui/react";
import WaterSampleReportMain from "../ReportPage/WaterSampleReportMain";
import ReportInformation from "../ReportPage/ReportInformation";
import TaxonomyReport from "../ReportPage/TaxonomyReport";
import HowItWorks from "../ReportPage/HowItWorks";
import { useParams } from "react-router";
import { useContext, useEffect } from "react";
import {
  fetchContentChunks,
  fetchSingleReport,
} from "../../services/fetchAllData";
import {
  ContentChunkModel,
  SingleSampleResponse,
} from "../../models/ContextParsedDataStructure";
import { ReportContext } from "../../context/ReportContext";
import { Header } from "../Layout/Header";
const FullReport = () => {
  const { id } = useParams<{ id: string }>();
  const { setReport, setContentChunks } = useContext(ReportContext);

  useEffect(() => {
    if (id) {
      (async () => {
        const res: SingleSampleResponse | null = await fetchSingleReport(id);
        res ? setReport(res) : setReport(null);
      })();
      (async () => {
        const res: ContentChunkModel[] | null = await fetchContentChunks();
        res ? setContentChunks(res) : setContentChunks(null);
      })();
    }
  }, [id, setContentChunks, setReport]);

  return (
    <>
      <Header title="FULL REPORT" />
      <Box className={"page-holder"} bg={"gray_neutral.100"} py="40px">
        <VStack id="print-container" spacing="10px">
          <WaterSampleReportMain />
          <TaxonomyReport />
          <HowItWorks />
          <ReportInformation />
        </VStack>
      </Box>
    </>
  );
};
export default FullReport;
