import React, { useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Button, Box, Text, Icon, Flex } from "@chakra-ui/react";
import { downArrow, upArrow } from "../../../icons/Icons";

type MapProps = {
  getCoordinates: (e: any) => void;
};

type LoadScriptLibraries =
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization";

function Map(props: MapProps) {
  const [libraries] = useState<LoadScriptLibraries[]>(["places"]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  const Markers = [
    {
      lat: "42.96115",
      lng: "-85.95859",
      label: "01",
    },
    {
      lat: "42.96137",
      lng: "-85.95940",
      label: "02",
    },
    {
      lat: "42.96161",
      lng: "-85.96029",
      label: "03",
    },
    {
      lat: "42.96191",
      lng: "-85.96143",
      label: "04",
    },
    {
      lat: "42.96201",
      lng: "-85.96372",
      label: "05",
    },
    {
      lat: "42.96229",
      lng: "-85.96710",
      label: "06",
    },
  ];

  const [marker, setMarker] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const [displaySD, setDisplaySD] = useState(false);

  if (!isLoaded) {
    return <div>Map is loading...</div>;
  }

  return (
    <Box>
      <Flex
        flexDir={{ lg: "row", base: "column" }}
        gap={{ base: "10px" }}
        justify="space-between"
        align="flex-start"
      >
        <Text fontWeight="600">
          Click the sample location on the map to get coordinates
        </Text>
        <Button variant="toggle" onClick={() => setDisplaySD(!displaySD)}>
          <Text pr="5px" fontWeight="600">
            {displaySD ? "Hide" : "Show"} Sevey Drain Markers
          </Text>
          <Icon as={displaySD ? downArrow : upArrow} />
        </Button>
      </Flex>
      <GoogleMap
        zoom={14}
        center={marker ? marker : { lat: 42.96229, lng: -85.96705 }}
        mapContainerStyle={{ height: "50vh", width: "100%" }}
        onClick={(e: google.maps.MapMouseEvent) => {
          if (e.latLng) {
            setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            props.getCoordinates(
              e.latLng.lat().toFixed(5) + ", " + e.latLng.lng().toFixed(5)
            );
          }
        }}
      >
        {marker && (
          <Marker
            position={{ lat: marker.lat, lng: marker.lng }}
            label="Clicked Location"
          />
        )}
        {displaySD &&
          Markers.map((marker, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(marker.lat),
                lng: parseFloat(marker.lng),
              }}
              label={marker.label}
              onClick={() => {
                setMarker({
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                });
                props.getCoordinates(marker.lat + ", " + marker.lng);
              }}
              zIndex={4}
            />
          ))}
      </GoogleMap>
    </Box>
  );
}

export default Map;
