import { css } from "@emotion/react";
export function BizLogoWhite(props: any) {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 17.5014C35 27.1665 27.1638 35.0028 17.4986 35.0028C7.83348 35.0028 0 27.1665 0 17.5014C0 12.7115 1.92532 8.37058 5.04328 5.2113V17.5014C5.04328 24.3708 10.6319 29.9567 17.4986 29.9567C24.3653 29.9567 29.9567 24.3681 29.9567 17.5014C29.9567 10.6347 24.3681 5.04328 17.5014 5.04328C13.9152 5.04328 10.6815 6.56646 8.40914 8.99858V2.54505C11.0561 0.930983 14.1713 0 17.5014 0C27.1665 0 35.0028 7.83623 35.0028 17.5014H35ZM18.8979 17.6914C18.6913 19.1127 18.3525 20.6001 17.5179 22.0048C17.1047 22.7017 15.5706 24.7812 12.8795 25.3211C14.2347 26.1254 15.8129 26.5964 17.5014 26.5964C22.5144 26.5964 26.5936 22.5171 26.5936 17.5041C26.5936 15.6559 26.1364 12.6729 23.2415 12.5738C19.7737 12.4526 19.0824 16.5043 18.8979 17.6942V17.6914ZM16.0994 17.3113C16.306 15.8901 16.6448 14.4027 17.4793 12.998C17.8925 12.3011 19.4267 10.2215 22.1177 9.68167C20.7626 8.87739 19.1843 8.40639 17.4959 8.40639C12.4829 8.40639 8.40364 12.4856 8.40364 17.4986C8.40364 19.3468 8.86086 22.3298 11.7557 22.429C15.2235 22.5502 15.9149 18.4985 16.0994 17.3086V17.3113Z"
        fill="white"
      />
    </svg>
  );
}

export function BizStreamLogoTextW() {
  return (
    <svg
      width="199"
      height="35"
      viewBox="0 0 199 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1591_651)">
        <path
          d="M44.9874 5.80349H52.2232C55.9389 5.80349 58.2333 6.22491 59.8473 7.12835C61.5275 8.06485 62.6568 9.58251 62.6568 11.6841C62.6568 13.7857 61.398 15.3034 59.1036 16.1738V16.2371C62.3676 17.3361 63.6263 19.4047 63.6263 21.9883C63.6263 24.7013 62.2381 26.8029 59.5554 27.9983C57.7788 28.8054 55.421 29.1937 52.5785 29.1937H44.9874V5.80349V5.80349ZM53.0633 15.1409C55.0657 15.1409 56.0022 14.5266 56.0022 13.1054C56.0022 12.3617 55.7433 11.8136 55.0327 11.4583C54.5479 11.1993 53.8042 11.1029 52.416 11.1029H51.6393V15.1409H53.0605H53.0633ZM52.6116 23.8971C54.5176 23.8971 55.4871 23.7346 56.0353 23.4453C56.6825 23.09 56.9717 22.5089 56.9717 21.6688C56.9717 20.085 55.8727 19.4074 53.7409 19.4074H51.6393V23.8971H52.6088H52.6116Z"
          fill="white"
        />
        <path
          d="M68.9451 4.67419C70.8181 4.67419 72.3054 6.16156 72.3054 8.00149C72.3054 9.84142 70.8181 11.3288 68.9451 11.3288C67.0721 11.3288 65.5847 9.84142 65.5847 8.00149C65.5847 6.16156 67.0721 4.67419 68.9451 4.67419ZM65.7472 13.3009H72.1429V29.1965H65.7472V13.3009V13.3009Z"
          fill="white"
        />
        <path
          d="M73.7294 29.1965L73.6964 29.1304L79.9323 18.4048L79.8993 18.3387H74.4401V13.2982H89.4294L89.4625 13.3615L83.2266 24.0871L83.2596 24.1532H89.2697V29.1937H73.7294V29.1965Z"
          fill="white"
        />
        <path
          d="M91.5834 21.6357C94.492 23.3159 97.7863 24.0265 99.4031 24.0265C100.761 24.0265 101.731 23.5417 101.731 22.3463C101.731 20.6662 99.8576 20.6662 96.8856 19.6333C93.3324 18.4048 90.8755 16.6613 90.8755 12.8823C90.8755 8.32651 94.3956 5.45093 99.6317 5.45093C102.056 5.45093 105.061 6.06516 107.256 7.16416V13.3037C104.446 11.7199 101.345 10.9762 99.6951 10.9762C98.4666 10.9762 97.6265 11.3646 97.6265 12.3975C97.6265 13.9152 99.4361 13.9152 102.279 14.9178C105.931 16.2096 108.482 18.0192 108.482 21.9607C108.482 26.679 104.766 29.5518 99.3397 29.5518C96.9489 29.5518 93.9439 29.0009 91.5861 27.8716V21.6357H91.5834Z"
          fill="white"
        />
        <path
          d="M121.799 28.7751C120.896 29.1965 119.345 29.5518 117.824 29.5518C115.855 29.5518 114.175 28.9376 113.139 27.9047C112.236 27.0013 111.588 25.6433 111.588 22.8972V18.3415H109.231V13.3009H111.588V8.77823H117.984V13.3009H121.796V18.3415H117.984V22.4455C117.984 23.7043 118.373 24.4149 119.568 24.4149C120.279 24.4149 121.119 24.156 121.796 23.8998V28.7778L121.799 28.7751Z"
          fill="white"
        />
        <path
          d="M124.305 13.3009H130.574V15.0142L130.638 15.0472C131.671 13.8518 133.125 13.0117 134.869 13.0117C135.29 13.0117 135.742 13.0751 136.031 13.1412V19.0218C135.45 18.8923 134.802 18.829 134.287 18.829C132.929 18.829 131.767 19.1513 130.701 20.1539V29.1993H124.305V13.3009V13.3009Z"
          fill="white"
        />
        <path
          d="M152.483 28.3234C150.803 29.1001 148.445 29.5518 146.28 29.5518C140.658 29.5518 136.813 26.4504 136.813 21.4099C136.813 16.3693 140.917 12.9456 145.44 12.9456C149.348 12.9456 153.389 15.3695 153.389 21.0876C153.389 21.6054 153.356 22.2499 153.293 22.7017H143.311C143.925 24.3818 145.443 24.963 147.156 24.963C148.643 24.963 150.58 24.5113 152.486 23.4756V28.3206L152.483 28.3234ZM147.638 19.4074C147.346 17.9531 146.346 17.4683 145.41 17.4683C144.278 17.4683 143.504 18.179 143.212 19.4074H147.638Z"
          fill="white"
        />
        <path
          d="M167.717 14.4963C166.359 13.4634 164.261 12.9456 161.966 12.9456C159.672 12.9456 157.444 13.4965 156.086 14.108V19.0824C157.41 18.5315 159.025 18.0164 160.705 18.0164C161.545 18.0164 162.352 18.1459 162.837 18.5976C163.159 18.9199 163.288 19.2752 163.288 19.76V19.9858C162.512 19.8233 161.738 19.7269 160.864 19.7269C159.314 19.7269 157.634 20.0189 156.405 20.9223C155.339 21.6963 154.532 23.0212 154.532 24.7344C154.532 26.4476 155.309 27.7064 156.568 28.5465C157.601 29.224 158.862 29.5491 160.316 29.5491C161.771 29.5491 162.974 29.0395 163.79 28.3399L164.128 29.1937H169.684V19.0493C169.684 17.0469 169.136 15.5595 167.715 14.4936L167.717 14.4963ZM163.291 25.0925C162.969 25.2219 162.547 25.3183 162.162 25.3183C161.418 25.3183 160.741 24.963 160.741 24.1863C160.741 23.3462 161.517 23.0239 162.355 23.0239C162.58 23.0239 163.002 23.057 163.291 23.1203V25.0897V25.0925Z"
          fill="white"
        />
        <path
          d="M191.92 20.1814C191.92 19.4708 191.758 18.9529 191.468 18.6637C191.146 18.3415 190.725 18.212 190.24 18.212C189.562 18.212 188.852 18.504 188.301 18.9557V29.1965H181.905V20.1814C181.905 19.4708 181.743 18.9529 181.454 18.6637C181.131 18.3415 180.71 18.212 180.225 18.212C179.547 18.212 178.804 18.504 178.286 18.9887V29.1965H171.89V13.3009H178.159V14.4633L178.223 14.4963C179.352 13.6893 180.839 12.9456 182.682 12.9456C184.296 12.9456 186.202 13.5598 187.205 14.9811C189.144 13.4937 190.887 12.9456 192.697 12.9456C194.507 12.9456 195.895 13.5929 196.801 14.4633C197.804 15.4659 198.319 16.8871 198.319 19.2118V29.1937H191.923V20.1786L191.92 20.1814Z"
          fill="white"
        />
        <path
          d="M35 17.5014C35 27.1665 27.1638 35.0028 17.4986 35.0028C7.83348 35.0028 0 27.1665 0 17.5014C0 12.7115 1.92532 8.37058 5.04328 5.2113V17.5014C5.04328 24.3708 10.6319 29.9567 17.4986 29.9567C24.3653 29.9567 29.9567 24.3681 29.9567 17.5014C29.9567 10.6347 24.3681 5.04328 17.5014 5.04328C13.9152 5.04328 10.6815 6.56646 8.40914 8.99858V2.54505C11.0561 0.930983 14.1713 0 17.5014 0C27.1665 0 35.0028 7.83623 35.0028 17.5014H35ZM18.8979 17.6914C18.6913 19.1127 18.3525 20.6001 17.5179 22.0048C17.1047 22.7017 15.5706 24.7812 12.8795 25.3211C14.2347 26.1254 15.8129 26.5964 17.5014 26.5964C22.5144 26.5964 26.5936 22.5171 26.5936 17.5041C26.5936 15.6559 26.1364 12.6729 23.2415 12.5738C19.7737 12.4526 19.0824 16.5043 18.8979 17.6942V17.6914ZM16.0994 17.3113C16.306 15.8901 16.6448 14.4027 17.4793 12.998C17.8925 12.3011 19.4267 10.2215 22.1177 9.68167C20.7626 8.87739 19.1843 8.40639 17.4959 8.40639C12.4829 8.40639 8.40364 12.4856 8.40364 17.4986C8.40364 19.3468 8.86086 22.3298 11.7557 22.429C15.2235 22.5502 15.9148 18.4985 16.0994 17.3086V17.3113Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1591_651">
          <rect width="198.316" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BizStreamLogoBlue() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 35"
      fill="none"
      height="23"
      width="23"
      className="bizstream-logo-blue"
    >
      <style>
        {`
          @media screen and (max-width: 768px) {
            .bizstream-logo-blue {
              display: block;
            }
          }
          @media screen and (min-width: 768px) {
            .bizstream-logo-blue {
              display: none;
            }
          }
          @media print {
            .bizstream-logo-blue {
              display: none;
            }
          }

        `}
      </style>
      <path
        d="M35 17.5014C35 27.1665 27.1638 35.0028 17.4986 35.0028C7.83348 35.0028 0 27.1665 0 17.5014C0 12.7115 1.92532 8.37058 5.04328 5.2113V17.5014C5.04328 24.3708 10.6319 29.9567 17.4986 29.9567C24.3653 29.9567 29.9567 24.3681 29.9567 17.5014C29.9567 10.6347 24.3681 5.04328 17.5014 5.04328C13.9152 5.04328 10.6815 6.56646 8.40914 8.99858V2.54505C11.0561 0.930983 14.1713 0 17.5014 0C27.1665 0 35.0028 7.83623 35.0028 17.5014H35ZM18.8979 17.6914C18.6913 19.1127 18.3525 20.6001 17.5179 22.0048C17.1047 22.7017 15.5706 24.7812 12.8795 25.3211C14.2347 26.1254 15.8129 26.5964 17.5014 26.5964C22.5144 26.5964 26.5936 22.5171 26.5936 17.5041C26.5936 15.6559 26.1364 12.6729 23.2415 12.5738C19.7737 12.4526 19.0824 16.5043 18.8979 17.6942V17.6914ZM16.0994 17.3113C16.306 15.8901 16.6448 14.4027 17.4793 12.998C17.8925 12.3011 19.4267 10.2215 22.1177 9.68167C20.7626 8.87739 19.1843 8.40639 17.4959 8.40639C12.4829 8.40639 8.40364 12.4856 8.40364 17.4986C8.40364 19.3468 8.86086 22.3298 11.7557 22.429C15.2235 22.5502 15.9148 18.4985 16.0994 17.3086V17.3113Z"
        fill="#00C4E9"
      />
    </svg>
  );
}

export function BizStreamLogoTextB() {
  return (
    <svg
      width="150"
      height="28"
      viewBox="0 0 199 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="bizstream-logo-text-b"
    >
      <style>
        {`
          // @media screen and (max-width: 768px) {
          //   .bizstream-logo-text-b {
          //     display: none;
          //   }
          // }
          // @media screen and (min-width: 768px) {
          //   .bizstream-logo-text-b {
          //     display: block;
          //   }
          @media print {
            .bizstream-logo-text-b {
              display: block;
          }
          }
        `}
      </style>

      <path
        d="M44.9874 5.80347H52.2232C55.9389 5.80347 58.2333 6.22489 59.8473 7.12833C61.5275 8.06482 62.6568 9.58249 62.6568 11.6841C62.6568 13.7857 61.3981 15.3033 59.1037 16.1737V16.2371C62.3676 17.3361 63.6264 19.4046 63.6264 21.9882C63.6264 24.7013 62.2382 26.8029 59.5554 27.9983C57.7788 28.8054 55.421 29.1937 52.5785 29.1937H44.9874V5.80347V5.80347ZM53.0633 15.1408C55.0657 15.1408 56.0022 14.5266 56.0022 13.1053C56.0022 12.3617 55.7433 11.8135 55.0327 11.4582C54.5479 11.1993 53.8042 11.1029 52.416 11.1029H51.6393V15.1408H53.0605H53.0633ZM52.6116 23.897C54.5176 23.897 55.4872 23.7345 56.0353 23.4453C56.6826 23.09 56.9718 22.5088 56.9718 21.6687C56.9718 20.085 55.8728 19.4074 53.7409 19.4074H51.6393V23.897H52.6088H52.6116Z"
        fill="#003D78"
      />
      <path
        d="M68.9454 4.67419C70.8184 4.67419 72.3058 6.16156 72.3058 8.00149C72.3058 9.84142 70.8184 11.3288 68.9454 11.3288C67.0725 11.3288 65.5851 9.84142 65.5851 8.00149C65.5851 6.16156 67.0725 4.67419 68.9454 4.67419ZM65.7476 13.3009H72.1433V29.1965H65.7476V13.3009V13.3009Z"
        fill="#003D78"
      />
      <path
        d="M73.7295 29.1965L73.6964 29.1304L79.9323 18.4049L79.8993 18.3387H74.4401V13.2982H89.4295L89.4625 13.3616L83.2266 24.0872L83.2596 24.1533H89.2697V29.1938H73.7295V29.1965Z"
        fill="#003D78"
      />
      <path
        d="M91.583 21.6357C94.4916 23.3159 97.7859 24.0265 99.4027 24.0265C100.761 24.0265 101.73 23.5417 101.73 22.3463C101.73 20.6662 99.8572 20.6662 96.8852 19.6333C93.332 18.4048 90.8751 16.6613 90.8751 12.8823C90.8751 8.32651 94.3952 5.45093 99.6313 5.45093C102.055 5.45093 105.06 6.06516 107.255 7.16416V13.3037C104.446 11.7199 101.345 10.9762 99.6947 10.9762C98.4662 10.9762 97.6261 11.3646 97.6261 12.3975C97.6261 13.9152 99.4358 13.9152 102.278 14.9178C105.931 16.2096 108.481 18.0192 108.481 21.9607C108.481 26.679 104.766 29.5518 99.3394 29.5518C96.9485 29.5518 93.9435 29.0009 91.5858 27.8716V21.6357H91.583Z"
        fill="#003D78"
      />
      <path
        d="M121.799 28.7751C120.896 29.1965 119.345 29.5518 117.824 29.5518C115.855 29.5518 114.175 28.9376 113.139 27.9047C112.236 27.0012 111.589 25.6433 111.589 22.8972V18.3414H109.231V13.3009H111.589V8.7782H117.984V13.3009H121.796V18.3414H117.984V22.4455C117.984 23.7042 118.373 24.4149 119.568 24.4149C120.279 24.4149 121.119 24.1559 121.796 23.8998V28.7778L121.799 28.7751Z"
        fill="#003D78"
      />
      <path
        d="M124.306 13.3009H130.575V15.0142L130.638 15.0472C131.671 13.8518 133.125 13.0117 134.869 13.0117C135.29 13.0117 135.742 13.0751 136.031 13.1412V19.0218C135.45 18.8923 134.803 18.829 134.288 18.829C132.93 18.829 131.767 19.1512 130.701 20.1538V29.1993H124.306V13.3009V13.3009Z"
        fill="#003D78"
      />
      <path
        d="M152.483 28.3234C150.803 29.1002 148.445 29.5519 146.28 29.5519C140.659 29.5519 136.814 26.4504 136.814 21.4099C136.814 16.3694 140.918 12.9457 145.44 12.9457C149.349 12.9457 153.39 15.3695 153.39 21.0876C153.39 21.6055 153.356 22.25 153.293 22.7017H143.311C143.925 24.3819 145.443 24.9631 147.156 24.9631C148.644 24.9631 150.58 24.5114 152.486 23.4757V28.3207L152.483 28.3234ZM147.638 19.4075C147.346 17.9532 146.347 17.4684 145.41 17.4684C144.278 17.4684 143.504 18.179 143.212 19.4075H147.638Z"
        fill="#003D78"
      />
      <path
        d="M167.717 14.4964C166.36 13.4635 164.261 12.9457 161.966 12.9457C159.672 12.9457 157.444 13.4966 156.086 14.108V19.0825C157.411 18.5316 159.025 18.0165 160.705 18.0165C161.545 18.0165 162.352 18.146 162.837 18.5977C163.159 18.9199 163.288 19.2753 163.288 19.76V19.9859C162.512 19.8234 161.738 19.727 160.865 19.727C159.314 19.727 157.634 20.0189 156.405 20.9224C155.339 21.6964 154.532 23.0212 154.532 24.7345C154.532 26.4477 155.309 27.7064 156.568 28.5465C157.601 29.2241 158.862 29.5491 160.316 29.5491C161.771 29.5491 162.974 29.0396 163.79 28.3399L164.128 29.1938H169.684V19.0494C169.684 17.047 169.136 15.5596 167.715 14.4936L167.717 14.4964ZM163.291 25.0925C162.969 25.222 162.547 25.3184 162.162 25.3184C161.418 25.3184 160.741 24.9631 160.741 24.1863C160.741 23.3462 161.517 23.024 162.355 23.024C162.58 23.024 163.002 23.057 163.291 23.1204V25.0898V25.0925Z"
        fill="#003D78"
      />
      <path
        d="M191.921 20.1815C191.921 19.4708 191.758 18.953 191.469 18.6638C191.147 18.3415 190.725 18.2121 190.24 18.2121C189.563 18.2121 188.852 18.504 188.301 18.9558V29.1966H181.906V20.1815C181.906 19.4708 181.743 18.953 181.454 18.6638C181.132 18.3415 180.71 18.2121 180.226 18.2121C179.548 18.2121 178.804 18.504 178.286 18.9888V29.1966H171.891V13.301H178.16V14.4633L178.223 14.4964C179.352 13.6894 180.84 12.9457 182.682 12.9457C184.297 12.9457 186.203 13.5599 187.205 14.9812C189.144 13.4938 190.888 12.9457 192.697 12.9457C194.507 12.9457 195.895 13.593 196.801 14.4633C197.804 15.4659 198.319 16.8872 198.319 19.2119V29.1938H191.923V20.1787L191.921 20.1815Z"
        fill="#003D78"
      />
      <path
        d="M35 17.5014C35 27.1665 27.1638 35.0028 17.4986 35.0028C7.83348 35.0028 0 27.1665 0 17.5014C0 12.7115 1.92532 8.37058 5.04328 5.2113V17.5014C5.04328 24.3708 10.6319 29.9567 17.4986 29.9567C24.3653 29.9567 29.9567 24.3681 29.9567 17.5014C29.9567 10.6347 24.3681 5.04328 17.5014 5.04328C13.9152 5.04328 10.6815 6.56646 8.40914 8.99858V2.54505C11.0561 0.930983 14.1713 0 17.5014 0C27.1665 0 35.0028 7.83623 35.0028 17.5014H35ZM18.8979 17.6914C18.6913 19.1127 18.3525 20.6001 17.5179 22.0048C17.1047 22.7017 15.5706 24.7812 12.8795 25.3211C14.2347 26.1254 15.8129 26.5964 17.5014 26.5964C22.5144 26.5964 26.5936 22.5171 26.5936 17.5041C26.5936 15.6559 26.1364 12.6729 23.2415 12.5738C19.7737 12.4526 19.0824 16.5043 18.8979 17.6942V17.6914ZM16.0994 17.3113C16.306 15.8901 16.6448 14.4027 17.4793 12.998C17.8925 12.3011 19.4267 10.2215 22.1177 9.68167C20.7626 8.87739 19.1843 8.40639 17.4959 8.40639C12.4829 8.40639 8.40364 12.4856 8.40364 17.4986C8.40364 19.3468 8.86086 22.3298 11.7557 22.429C15.2235 22.5502 15.9148 18.4985 16.0994 17.3086V17.3113Z"
        fill="#00C4E9"
      />

      <defs>
        <clipPath id="clip0_1591_51">
          <rect width="198.316" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SmallBlueBar() {
  return (
    <svg
      width="120"
      height="4"
      viewBox="0 0 120 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="120" height="4" fill="#79CFFF" />
    </svg>
  );
}

export function BlueLine(props: any) {
  return (
    <svg
      width="100%"
      height="1"
      viewBox="0 0 1575 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4.37114e-08"
        y1="0.5"
        x2="1575"
        y2="0.500094"
        stroke="#79CFFF"
      />
    </svg>
  );
}
export function BlueDottedLine(props: any) {
  return (
    <svg
      width="100%"
      height="1"
      viewBox="0 0 1575 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4.37114e-08"
        y1="0.5"
        x2="1575"
        y2="0.500094"
        stroke="#79CFFF"
        strokeDasharray="5 5"
        strokeDashoffset="0"
      />
    </svg>
  );
}

export function ReportIcon(props: any) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.90625 0.875 0 2 0H7V4C7 4.5625 7.4375 5 8 5H12V14C12 15.125 11.0938 16 10 16H2C0.875 16 0 15.125 0 14V2ZM8 4V0L12 4H8Z"
        fill="#2568CC"
      />
    </svg>
  );
}

export function xIcon(props: any) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3047 0.304688C12.6797 -0.101562 13.3359 -0.101562 13.7109 0.304688C14.1172 0.679688 14.1172 1.33594 13.7109 1.71094L8.42969 6.99219L13.7109 12.3047C14.1172 12.6797 14.1172 13.3359 13.7109 13.7109C13.3359 14.1172 12.6797 14.1172 12.3047 13.7109L6.99219 8.42969L1.71094 13.7109C1.33594 14.1172 0.679688 14.1172 0.304688 13.7109C-0.101562 13.3359 -0.101562 12.6797 0.304688 12.3047L5.58594 6.99219L0.304688 1.71094C-0.101562 1.33594 -0.101562 0.679688 0.304688 0.304688C0.679688 -0.101562 1.33594 -0.101562 1.71094 0.304688L6.99219 5.58594L12.3047 0.304688Z"
        fill="#2568CC"
      />
    </svg>
  );
}

export function arrowIcon() {
  return (
    <svg
      width="82"
      height="77"
      viewBox="0 0 82 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.2568 33.0019H77.7588C80.1092 33.0019 82 31.1631 82 28.8775V6.88063C82 5.21368 80.9751 3.70139 79.3846 3.06554C77.7942 2.4297 75.974 2.7734 74.7547 3.95917L67.4034 11.1082C51.9232 -3.75693 26.9887 -3.70537 11.5969 11.28C-3.86563 26.3169 -3.86563 50.6854 11.5969 65.7223C27.0594 80.7592 52.1176 80.7592 67.5801 65.7223C69.789 63.5742 69.789 60.0856 67.5801 57.9375C65.3712 55.7893 61.7838 55.7893 59.5749 57.9375C48.5303 68.6781 30.6291 68.6781 19.5844 57.9375C8.53973 47.1968 8.53973 29.7883 19.5844 19.0477C30.576 8.35854 48.3359 8.30699 59.3982 18.8758L52.1352 25.9561C50.9159 27.1418 50.5625 28.9119 51.2163 30.4585C51.8702 32.0052 53.4252 33.0019 55.1394 33.0019H76.2568Z"
        fill="#003D78"
      />
    </svg>
  );
}

export function houseIcon() {
  return (
    <svg
      width="89"
      height="77"
      viewBox="0 0 89 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.9767 38.4248C88.9767 41.1318 86.6588 43.2523 84.0318 43.2523H79.0869L79.1951 67.3449C79.1951 67.751 79.1642 68.157 79.1178 68.5631V70.9844C79.1178 74.308 76.3518 77 72.9368 77H70.4643C70.2943 77 70.1244 77 69.9544 76.985C69.738 77 69.5217 77 69.3054 77H64.2832H60.5746C57.1595 77 54.3935 74.308 54.3935 70.9844V67.375V57.75C54.3935 55.0881 52.1838 52.9375 49.4486 52.9375H39.5589C36.8238 52.9375 34.6141 55.0881 34.6141 57.75V67.375V70.9844C34.6141 74.308 31.848 77 28.433 77H24.7243H19.7949C19.5631 77 19.3313 76.985 19.0995 76.9699C18.9141 76.985 18.7287 77 18.5432 77H16.0708C12.6558 77 9.88973 74.308 9.88973 70.9844V54.1406C9.88973 54.0053 9.88973 53.8549 9.90518 53.7195V43.2523H4.94486C2.16338 43.2523 0 41.1469 0 38.4248C0 37.0713 0.463581 35.8682 1.54527 34.8154L41.166 1.20312C42.2477 0.150391 43.4839 0 44.5656 0C45.6473 0 46.8835 0.300781 47.8107 1.05273L87.2769 34.8154C88.5131 35.8682 89.1312 37.0713 88.9767 38.4248Z"
        fill="#003D78"
      />
    </svg>
  );
}

export function fileIcon() {
  return (
    <svg
      width="57"
      height="77"
      viewBox="0 0 57 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.625C0 4.31621 4.26016 0 9.5 0H33.25V19.25C33.25 21.9119 35.3727 24.0625 38 24.0625H57V67.375C57 72.6838 52.7398 77 47.5 77H9.5C4.26016 77 0 72.6838 0 67.375V9.625ZM57 19.25H38V0L57 19.25Z"
        fill="#003D78"
      />
    </svg>
  );
}

export function rightArrow() {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13C1.71875 13 1.46875 12.9062 1.28125 12.7188C0.875 12.3438 0.875 11.6875 1.28125 11.3125L5.5625 7L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L7.6875 6.3125C8.09375 6.6875 8.09375 7.34375 7.6875 7.71875L2.6875 12.7188C2.5 12.9062 2.25 13 2 13Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}

export function trashCan() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21875 0.5625C4.375 0.21875 4.71875 0 5.09375 0H8.875C9.25 0 9.59375 0.21875 9.75 0.5625L10 1H13C13.5312 1 14 1.46875 14 2C14 2.5625 13.5312 3 13 3H1C0.4375 3 0 2.5625 0 2C0 1.46875 0.4375 1 1 1H4L4.21875 0.5625ZM0.96875 4H13V14C13 15.125 12.0938 16 11 16H2.96875C1.875 16 0.96875 15.125 0.96875 14V4ZM3.46875 6.5V13.5C3.46875 13.7812 3.71875 14 3.96875 14C4.25 14 4.46875 13.7812 4.46875 13.5V6.5C4.46875 6.25 4.25 6 3.96875 6C3.71875 6 3.46875 6.25 3.46875 6.5ZM6.46875 6.5V13.5C6.46875 13.7812 6.71875 14 6.96875 14C7.25 14 7.5 13.7812 7.5 13.5V6.5C7.5 6.25 7.25 6 6.96875 6C6.71875 6 6.46875 6.25 6.46875 6.5ZM9.5 6.5V13.5C9.5 13.7812 9.71875 14 10 14C10.25 14 10.5 13.7812 10.5 13.5V6.5C10.5 6.25 10.25 6 10 6C9.71875 6 9.5 6.25 9.5 6.5Z"
        fill="#CE1A1A"
      />
    </svg>
  );
}

export function downArrow() {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1.5C12.5 1.21875 12.4062 0.968751 12.2187 0.781251C11.8437 0.375001 11.1875 0.375001 10.8125 0.781251L6.5 5.0625L2.21875 0.78125C1.84375 0.375 1.1875 0.375 0.8125 0.78125C0.40625 1.15625 0.40625 1.8125 0.8125 2.1875L5.8125 7.1875C6.1875 7.59375 6.84375 7.59375 7.21875 7.1875L12.2187 2.1875C12.4062 2 12.5 1.75 12.5 1.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}

export function upArrow() {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 6.5C0.5 6.78125 0.59375 7.03125 0.78125 7.21875C1.15625 7.625 1.8125 7.625 2.1875 7.21875L6.5 2.9375L10.7813 7.21875C11.1563 7.625 11.8125 7.625 12.1875 7.21875C12.5938 6.84375 12.5938 6.1875 12.1875 5.8125L7.1875 0.8125C6.8125 0.40625 6.15625 0.40625 5.78125 0.8125L0.78125 5.8125C0.59375 6 0.5 6.25 0.5 6.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}

export function navMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity="0.5"
    >
      <path d="M3 12h18" stroke="white" />
      <path d="M3 6h18" stroke="white" />
      <path d="M3 18h18" stroke="white" />
    </svg>
  );
}

export function prevPageIcon() {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68708 4.82026e-08C8.04789 7.97448e-08 8.3686 0.117264 8.60913 0.351792C9.13029 0.820847 9.13029 1.64169 8.60913 2.11075L3.11693 7.50489L8.60913 12.8599C9.13029 13.329 9.13029 14.1498 8.60913 14.6189C8.12806 15.127 7.28619 15.127 6.80512 14.6189L0.390869 8.36482C-0.130289 7.89576 -0.130289 7.07492 0.39087 6.60586L6.80512 0.351791C7.04566 0.117264 7.36637 2.0165e-08 7.68708 4.82026e-08Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}

export function emailIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4C1.34375 4 0 5.34375 0 7C0 7.94375 0.44375 8.83125 1.2 9.4L14.8 19.6C15.5125 20.1313 16.4875 20.1313 17.2 19.6L30.8 9.4C31.5562 8.83125 32 7.94375 32 7C32 5.34375 30.6562 4 29 4H3ZM0 11V24C0 26.2062 1.79375 28 4 28H28C30.2062 28 32 26.2062 32 24V11L18.4 21.2C16.975 22.2688 15.025 22.2688 13.6 21.2L0 11Z"
        fill="#003D78"
      />
    </svg>
  );
}

export function linkIcon() {
  return (
    <svg
      width="35"
      height="28"
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.7079 14.6398C34.7977 11.5499 34.7977 6.54604 31.7079 3.45619C28.9735 0.721816 24.6641 0.366347 21.5196 2.614L21.4321 2.67416C20.6446 3.23744 20.4641 4.33119 21.0274 5.11322C21.5907 5.89525 22.6844 6.08119 23.4665 5.51791L23.554 5.45775C25.3094 4.20541 27.7102 4.40229 29.2305 5.92807C30.9532 7.65072 30.9532 10.4398 29.2305 12.1624L23.0946 18.3093C21.3719 20.032 18.5829 20.032 16.8602 18.3093C15.3344 16.7835 15.1375 14.3828 16.3899 12.6328L16.45 12.5453C17.0133 11.7578 16.8274 10.664 16.0454 10.1062C15.2633 9.54838 14.1641 9.72885 13.6063 10.5109L13.5461 10.5984C11.293 13.7374 11.6485 18.0468 14.3829 20.7812C17.4727 23.871 22.4766 23.871 25.5665 20.7812L31.7079 14.6398ZM3.29224 13.3601C0.202393 16.4499 0.202393 21.4538 3.29224 24.5437C6.02661 27.2781 10.336 27.6335 13.4805 25.3859L13.568 25.3257C14.3555 24.7624 14.536 23.6687 13.9727 22.8867C13.4094 22.1046 12.3157 21.9187 11.5336 22.482L11.4461 22.5421C9.69067 23.7945 7.28989 23.5976 5.76958 22.0718C4.04692 20.3437 4.04692 17.5546 5.76958 15.832L11.9055 9.69057C13.6282 7.96791 16.4172 7.96791 18.1399 9.69057C19.6657 11.2163 19.8625 13.6171 18.6102 15.3726L18.55 15.4601C17.9868 16.2476 18.1727 17.3413 18.9547 17.8992C19.7368 18.457 20.836 18.2765 21.3938 17.4945L21.454 17.407C23.7071 14.2624 23.3516 9.95307 20.6172 7.21869C17.5274 4.12885 12.5235 4.12885 9.43364 7.21869L3.29224 13.3601Z"
        fill="#003D78"
      />
    </svg>
  );
}

export function addIcon() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57136 2.65625C8.57136 2.06855 8.09256 1.59375 7.49993 1.59375C6.9073 1.59375 6.4285 2.06855 6.4285 2.65625V7.4375H1.60707C1.01444 7.4375 0.535645 7.9123 0.535645 8.5C0.535645 9.0877 1.01444 9.5625 1.60707 9.5625H6.4285V14.3438C6.4285 14.9314 6.9073 15.4062 7.49993 15.4062C8.09256 15.4062 8.57136 14.9314 8.57136 14.3438V9.5625H13.3928C13.9854 9.5625 14.4642 9.0877 14.4642 8.5C14.4642 7.9123 13.9854 7.4375 13.3928 7.4375H8.57136V2.65625Z"
        fill="#A0AEC0"
      />
    </svg>
  );
}
