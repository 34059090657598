// create a function that takes in a hex color and returns a hex color that is either white or black, depending on which one stands out more
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
const getTextColorBasedOnBgColor = (bgColor: string, lightColor: string = "#ffffff", darkColor: string = "#000000") => {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}



export { getTextColorBasedOnBgColor }