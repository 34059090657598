import { Box, Flex } from "@chakra-ui/react";
import { Range } from "../../constants/constants";
import {
  getColorBasedOnValueFromRange,
  getRangeNameBasedOnValueFromRange,
} from "../../services/rangeService";
import { getTextColorBasedOnBgColor } from "../../services/colorService";
import { BlueLine } from "../../icons/Icons";
import WQRSlider from "./WQRSlider";
import { formatMetric } from "../../services/sample-calculation/calculationService";

type RangeIndexDisplayProps = {
  title: string;
  range: Range[];
  value: number | undefined;
};

const RangeIndexDisplay = (props: RangeIndexDisplayProps) => {
  const indexValue = props?.value;
  const indexRating = getRangeNameBasedOnValueFromRange(
    props.range,
    indexValue
  );

  const bgColor = getColorBasedOnValueFromRange(props.range, indexValue ?? 0);
  const fgColor = getTextColorBasedOnBgColor(bgColor);

  return (
    <Box pt={"6px"} pb={"10px"}>
      <Flex
        direction="row"
        align="baseline"
        justify="start"
        gap={4}
        width={"-webkit-fill-available"}
        pb={"4px"}
      >
        <Box
          fontWeight="bold"
          fontSize="20px"
          sx={{
            "@media screen and (max-width: 992px)": {
              fontSize: "16px",
            },
          }}
          lineHeight="25px"
          color={"primary.blue.brand"}
          textTransform={"uppercase"}
        >
          {props.title}:
        </Box>
        <Flex direction="column" gap="5px">
          <Box
            bg={bgColor}
            color={fgColor}
            fontWeight="bold"
            fontSize="16px"
            minWidth="120px"
            textAlign="center"
            borderRadius="5px"
            border={"1px solid var(--chakra-colors-gray-800)"}
          >
            {indexRating}
          </Box>
          <Box
            bg={bgColor}
            color={fgColor}
            fontWeight="bold"
            fontSize="16px"
            minWidth="120px"
            textAlign="center"
            borderRadius="5px"
            border={"1px solid var(--chakra-colors-gray-800)"}
            sx={{
              "@media print": {
                display: "none",
              },
              "@media screen and (min-width: 992px)": {
                display: "none",
              },
            }}
          >
            {formatMetric(indexValue)}
          </Box>
        </Flex>
      </Flex>

      <WQRSlider sliderRanges={props.range} indexValue={indexValue} />

      {/* Note: {getWQIRangeDescriptionBasedOnValue(
                    props.range,
                    indexValue
                  )} */}

      <Box bg={"primary.blue.brand"} h={"2px"} w="100%" as={BlueLine}></Box>
    </Box>
  );
};

export default RangeIndexDisplay;
