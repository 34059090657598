import {
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  Flex,
  FormControl,
  FormLabel,
  Tag,
} from "@chakra-ui/react";

interface Props {
  label: string;
  dbValue: string;
  suffix: string;
  handleSliderChange?: any;
  selectedValue: any;
  setSelectedValue: any;
}

const IndependentSlider = ({
  label,
  dbValue,
  suffix,
  handleSliderChange,
  selectedValue,
  setSelectedValue
}: Props) => {

  return (
    <FormControl>
      <Flex dir="row" align="flex-start">
      <Tag variant="SliderValue" w="55px">
          {selectedValue}{suffix}
        </Tag>
        <FormLabel
          htmlFor={dbValue}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {label}
        </FormLabel>
      </Flex>
      <Slider
        value={selectedValue}
        onChange={(value: number) => handleSliderChange(value, setSelectedValue, dbValue)}
        min={0}
        max={100}
      >
        <SliderTrack bg="gray_cool.400" height="3px">
          <SliderFilledTrack bg="primary.blue.600" />
        </SliderTrack>
        <SliderThumb bg="primary.blue.600" />
      </Slider>
    </FormControl>
  );
};

export default IndependentSlider;
