import {
  Box,
  Flex,
  Text,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import PageFooter from "../ReportParts/PageFooter";
import PageHeader from "../ReportParts/PageHeader";
import TaxonomyCatalog from "../ReportParts/TaxonomyCatalog";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import Page from "./PageTemplate";
import { ApiContext } from "../../context/ApiContext";

interface MappingDataStructure {
  ScrapersCount?: number;
  ShreddersCount?: number;
  PredatorsCount?: number;
  GCollectorsCount?: number;
  FCollectorsCount?: number;
  [key: string]: number | undefined;
}

const TaxonomyReport = () => {
  const { report } = useContext(ReportContext);
  const { fFGData } = useContext(ApiContext);
  const data = report;

  const mappings: Record<keyof MappingDataStructure, number | undefined> = {
    ScrapersCount: data?.scrapersCount,
    ShreddersCount: data?.shreddersCount,
    PredatorsCount: data?.predatorsCount,
    GCollectorsCount: data?.gCollectorsCount,
    FCollectorsCount: data?.fCollectorsCount,
  };

  return report ? (
    <Page>
      <Flex direction="column" justify="space-between" h="100%">
        <VStack>
          <Box maxW="100%">
            <PageHeader
              sampleNumber={report.reportId}
              pageTitle={"WATER QUALITY REPORT"}
            />
            <TaxonomyCatalog />

            <TableContainer
              my="15px"
              borderRadius="8px 8px 0 0"
              border="1px"
              borderColor="gray_cool.300"
              whiteSpace={"pre-wrap"}
            >
              <Table variant="report">
                <Thead>
                  <Tr>
                    <Td>FUNCTIONAL FEEDING GROUPS (FFG)</Td>
                    <Td textAlign="center">COUNT</Td>
                  </Tr>
                </Thead>

                <Tbody>
                  {fFGData &&
                    Object.entries(fFGData).map(([key, value]) => (
                      <Tr key={key}>
                        <Td>
                          <Text fontWeight="700">{value.displayName}</Text>
                          <Text fontSize="12px">{value.description}</Text>
                        </Td>
                        <Td textAlign="center">{mappings[value.dbValue]}</Td>
                      </Tr>
                    ))}

                  <Tr>
                    <Td fontWeight="700">Total:</Td>
                    <Td fontWeight="bold" textAlign="center">
                      {data &&
                        data?.shreddersCount +
                          data?.scrapersCount +
                          data?.gCollectorsCount +
                          data?.fCollectorsCount +
                          data?.predatorsCount}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </VStack>

        <PageFooter />
      </Flex>
    </Page>
  ) : null;
};

export default TaxonomyReport;
