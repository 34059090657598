

export const Button = {
    baseStyle: {
        lineHeight: "100%",
        fontWeight: "700",
        fontFamily: "font.body",
    },
    // styles for different sizes ("sm", "md", "lg")
    sizes: {},
    // styles for different visual variants ("outline", "solid")
    variants: {
        primary: {
            bg: "primary.blue.600",
            fontSize: "16px",
            color: "white",
            borderRadius: "25px",
            p: "12px 25px 13px 25px",
            _hover:{ boxShadow: "md" }, 
        },
        primaryInversed: {
            border: "2px #2568CC solid",
            fontSize: "16px",
            color: "primary.blue.600",
            borderRadius: "25px",
            p: "10px 23px 10px 23px",
            _hover:{ boxShadow: "md" },
        },
        primaryInversedSmall: {
            border: "2px #2568CC solid",
            fontSize: "16px",
            color: "primary.blue.600",
            borderRadius: "25px",
            p: "7px 15px",
            _hover:{ boxShadow: "md" },
        },
        primarySmall: { 
            bg: "primary.blue.600",
            fontSize: "16px",
            color: "white",
            borderRadius: "25px",
            p: "7px 15px",
            _hover:{ boxShadow: "md" },
        },
        add:{
            bg: "primary.blue.600",
            fontSize: "16px",
            color: "white",
            borderRadius: "xl",
            p: "12px 12px", 
            w: "fit-content", 
            h:"fit-content"
        },
        delete:{
            bg: "red",
            fontSize: "16px",
            color: "white",
            w: "xsm",
            borderRadius: "lg",
            p: "12px 25px 13px 25px",
        },
        toggle:{
            fontFamily: "font.body",
            fontSize: "16px",
            lineHeight: "25px", 
            color: "primary.blue.600", 
            fontWeight: "600"
        }
    },
    // default values for "size", "variant" and "colorScheme"
    defaultProps: {
        size: "",
        variant: "",
        colorScheme: "",
    },
}