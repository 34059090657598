import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

export const Table = defineMultiStyleConfig({
  baseStyle: (props) => definePartsStyle({}),

  variants: {
    samples: () =>
      definePartsStyle({
        tbody: defineStyle({
          "> tr:nth-of-type(even)": { bg: "primary.gray.brand" },
        }),
        thead: defineStyle({
          outline: "1px solid",
          outlineColor: "secondary.blue_accent.brand",
        }),
        td: defineStyle({
          fontFamily: "font.body",
          textAlign: "left",
        }),
        th: defineStyle({
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "20px",
          letterSpacing: "2%",
          fontFamily: "font.body",
          color: "primary.blue.brand",
          textAlign: "left",
        }),
      }),
    report: () =>
      definePartsStyle({
        table: defineStyle({
          borderRadius: "8px",
        }),
        thead: defineStyle({
          bg: "primary.blue.brand",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          w: "100%",
          h: "35px",
        }),
        tbody: defineStyle({
          "> tr:nth-of-type(even)": { bg: "primary.gray.brand" },
        }),
        tr: defineStyle({
          "> *:not(:first-of-type)": { w: "100px" },
        }),
        td: defineStyle({
          py: "5px",
          px: "10px",
        }),
      }),
    "report-even": () =>
      definePartsStyle({
        table: defineStyle({
          borderRadius: "8px",
        }),
        thead: defineStyle({
          bg: "primary.blue.brand",
          color: "white",
          fontSize: "14px",
          fontWeight: "600",
          w: "100%",
          h: "35px",
        }),
        tbody: defineStyle({
          "> tr:nth-of-type(even)": { bg: "primary.gray.brand" },
        }),
        td: defineStyle({
          py: "5px",
   
        }),
      }),
    test: () =>
      definePartsStyle({
        table: defineStyle({}),
        thead: defineStyle({
          bg: "gray_neutral.50",
          color: "white",
          fontSize: "16px",
          fontWeight: "600",
        }),
        tbody: defineStyle({}),
        tr: defineStyle({
          color: "black",
          fontFamily: "font.body",
        }),
        td: defineStyle({}),
        th: defineStyle({
          fontFamily: "font.body",
          fontSize: "15px",
          fontWeight: "700",
        }),
      }),
    "organisms": () =>
      definePartsStyle({
        table: defineStyle({}),
        thead: defineStyle({
          color: "white",
          fontSize: "16px",
          fontWeight: "600",
        }),
        tbody: defineStyle({
          "> tr:nth-of-type(even)": { bg: "secondary.blue_accent.50" },
        }),
        tr: defineStyle({
          textWrap: "wrap"
        }),
        td: defineStyle({
          fontFamily: "font.body",
          color: "primary.blue.brand",
          fontWeight: "bold",
          textWrap: "wrap"
        }),
      }),
  },
});
