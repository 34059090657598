import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
import { Text } from "./components/Text";
import { Tag } from "./components/Tag"
import { Button } from "./components/Button";
import { Table } from "./components/Table";
import { Card } from "./components/Card";
import { FormLabel } from "./components/FormLabel";

export const theme = extendTheme({ colors: styles.colors,
    fonts: styles.fonts,
    fontSizes: styles.fontSizes,
    space: styles.px,
    components: {
        Tag,
        Text,
        Button,
        Table,
        Card,
        FormLabel, 
    }, 
    initialColorMode: 'light',
    useSystemColorMode: false,
});