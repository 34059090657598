import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

export const Tag = defineMultiStyleConfig({
    baseStyle: () =>
        definePartsStyle({ 
        container: defineStyle({
            fontSize: "13px",
            fontWeight: 'bold',
            fontFamily: 'font.body',
            borderRadius: '4px',
            lineHeight: "25px",
        })
    }),

    variants: {
        'Excellent': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#41A841',
                }),
            }),
        'Very Good': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#489548',
                }),
            }),
        'Good': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#ABCF3F',
                }),
            }),
        'Fair': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#C5C535',
                }),
            }),
        'Fairly Poor': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#FF741B',
                }),
            }),
        'Poor': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#FF4C07',
                }),
            }),
        'Very Poor': () =>
            definePartsStyle({
                container: defineStyle({
                    bg: '#FF2A2A',
                }),
            }),
        'No Background': (props: StyleFunctionProps) =>
        definePartsStyle({
            container: defineStyle({
                p: "0",
                color: props.colorMode === 'dark' ? 'white' : 'black',
                w:"fit-content",
                fontSize: "md",
                bg: props.colorMode === 'dark' ? 'transparent' : 'white',
            }),
        }),
        'SliderValue': () =>
        definePartsStyle({
            container: defineStyle({
                fontSize: "16px",
                color: "primary.blue.600", 
                lineHeight: "25px"
            }),
        }),
    },

    defaultProps: {
        colorScheme: 'gray',
    },
});

export default Tag;