import FormHeading from "./Components/FormHeading";
import TextInput from "./Components/TextInput";

export default function YourInfo() {
    return (
        <>
            <FormHeading title="YOUR INFORMATION"></FormHeading>
            <TextInput
                label="Your Name / Organization"
                value="UserName"
                formType="text"
                isRequired={true}
            />
            <TextInput
                label="Your Email"
                value="UsersEmail"
                formType="text"
                isRequired={true}
                hasPattern={/^\S+@\S+$/i}
            />
        </>
    )
}