import {
  useToast,
  Button,
  FormControl,
  NumberDecrementStepper,
  NumberIncrementStepper,
  FormLabel,
  NumberInput,
  NumberInputStepper,
  NumberInputField,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useContext } from "react";
import { FamilyGroupsData } from "../../../models/ContextParsedDataStructure";
import { ApiContext } from "../../../context/ApiContext";
import ShowToast from "../ShowToast";

interface OrganismInputValidationProps {
  handleAddOrganism: () => void;
  organismNumber: string;
  orgId: string;
  value: string;
  countChange: any;
  inputRef?: any;
  countRef?: any;
}

function formatOrganisms(familyGroupsData: {
  [key: string]: FamilyGroupsData;
}) {
  return Object.entries(familyGroupsData).map(([key]) => {
    return {
      id: key,
    };
  });
}

export default function OrganismInputValidation({
  handleAddOrganism,
  organismNumber,
  orgId,
  value,
  countChange,
  inputRef,
  countRef,
}: OrganismInputValidationProps) {
  const toast = useToast();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const familyGroupsData = useContext(ApiContext).familyGroupsData;
  const organisms = useMemo(
    () => formatOrganisms(familyGroupsData),
    [familyGroupsData]
  );

  const handleInput = () => {
    if (
      Object.values(organisms).some(
        (familyGroup) => familyGroup.id === orgId
      ) &&
      organismNumber !== ""
    ) {
      handleAddOrganism();
      if (isDesktop) {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, 10);
      }
    } else {
      ShowToast({
        isDesktop: isDesktop,
        toast: toast,
        title: "Invalid Organism",
        description: "Please enter a valid organism",
        status: "error",
      });
    }
  };

  return (
    <>
      <Flex
        flexDir={{ lg: "row", md: "row", base: "column" }}
        align={{ lg: "flex-end", md: "flex-end", base: "flex-start" }}
        justify="flex-start"
        gap="md"
      >
        <FormControl>
          <FormLabel variant="nowrap">Enter the count</FormLabel>
          <NumberInput
            maxW="150px"
            min={1}
            ref={countRef}
            value={value}
            onChange={countChange}
            color="black"
            fontFamily="font.body"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleInput();
                setTimeout(() => {
                  inputRef?.current?.focus();
                }, 10);
              }
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper color="black" />
              <NumberDecrementStepper color="black" />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <Button onClick={handleInput} variant="add">
          Add
        </Button>
      </Flex>
    </>
  );
}
