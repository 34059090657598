import { Tag, Text, Flex, useBreakpointValue } from "@chakra-ui/react";
import { getTextColorBasedOnBgColor } from "../services/colorService";
import { type } from "os";

type QualityProps = {
  tagLabel: string;
  colorScheme: string;
  value?: string | number;
};

export const QualityIndicator = (props: QualityProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

      
  
  return (
    <Flex
      alignContent="flex-end"
      >
      {isDesktop && 
      <Text
        pr="8px"
        fontWeight="bold"
        fontSize="16px"
        lineHeight="25px"
      >
        {props.value}
      </Text>
      }
      <Tag
        justifyContent="center"
        fontSize="16px"
        bg={props.colorScheme}
        color={getTextColorBasedOnBgColor(props.colorScheme)}
        borderRadius="5px" 
        border={"1px solid var(--chakra-colors-gray-800)"} 
      >
        {props.tagLabel}
      </Tag>
    </Flex>
  );
};