import RadioButton from "./Components/RadioButton";
import { Flex } from "@chakra-ui/react";

interface RadioButtonOption {
  label: string;
  value: string;
}

export default function EstimateOptions() {
  const RadioButtonOptions: RadioButtonOption[] = [
    { value: "Abundant", label: "Abundant" },
    { value: "Moderate", label: "Moderate" },
    { value: "Sparse", label: "Sparse" },
    { value: "None", label: "None" },
  ];

  const WaterClarityOptions: RadioButtonOption[] = [
    { value: "<1", label: "Less than 1 inch" },
    { value: "1-3", label: "Between 1 and 3 inches" },
    { value: ">3", label: "Over 3 inches" },
  ];

  return (
    <>
      <Flex gap={{ lg: "57px", base: "sm" }} flexDirection="column" w="100%">
        <RadioButton
          label="Estimate amount of coniferous tree stream-side coverage:"
          dbValue="ConiferousCoverage"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of deciduous tree stream-side coverage:"
          dbValue="DeciduousCoverage"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of grass and/or shrubs stream-side coverage:"
          dbValue="GrassShrubCoverage"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of instream woody debris that are logs:"
          dbValue="LogDebris"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of instream woody debris that are roots:"
          dbValue="RootDebris"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of instream woody debris that are limbs:"
          dbValue="LimbDebris"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate amount of instream woody debris that is brush:"
          dbValue="BrushDebris"
          options={RadioButtonOptions}
        />
        <RadioButton
          label="Estimate how far down you can see clearly into the water:"
          dbValue="WaterClarity"
          options={WaterClarityOptions}
        />
      </Flex>
    </>
  );
}
