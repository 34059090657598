import { Box, Flex, VStack } from "@chakra-ui/react";
import PageFooter from "../ReportParts/PageFooter";
import PageHeader from "../ReportParts/PageHeader";
import EARData from "../ReportParts/EARData";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import Page from "./PageTemplate";

const HowItWorks = () => {
  const { report } = useContext(ReportContext);
  return report ? (
    <Page>
      <Flex direction="column" justify="space-between" h="100%">
        <VStack>
          <Box maxW="100%">
            <PageHeader
              sampleNumber={report.reportId}
              pageTitle={"HOW IT WORKS"}
            />

            <EARData />
          </Box>
        </VStack>
        <PageFooter />
      </Flex>
    </Page>
  ) : null;
};
export default HowItWorks;
