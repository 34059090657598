import {
  FamilyGroupsData,
  FfgData,
  MacroinvertebratesKeyData,
  OrdersData,
  SpecialAttributesData,
  SampleListItem
} from "../models/ContextParsedDataStructure";
import { createContext, useState } from "react";

type MyContextProps = {
  children: React.ReactNode;
};

//Context Data Structure
type APIParsedContextType = {
  familyGroupsData: { [key: string]: FamilyGroupsData };
  setFamilyGroupsData: React.Dispatch<
    React.SetStateAction<{ [key: string]: FamilyGroupsData }>
  >;
  ordersData: { [key: string]: OrdersData };
  setOrdersData: React.Dispatch<
    React.SetStateAction<{ [key: string]: OrdersData }>
  >;
  fFGData: { [key: string]: FfgData };
  setFFGData: React.Dispatch<
    React.SetStateAction<{ [key: string]: FfgData }>
  >;
  specialAttributeData: { [key: string]: SpecialAttributesData };
  setSpecialAttributeData: React.Dispatch<
    React.SetStateAction<{ [key: string]: SpecialAttributesData }>
  >;
  macroinvertebratesKeyData: { [key: string]: MacroinvertebratesKeyData };
  setMacroinvertebratesKeyData: React.Dispatch<
    React.SetStateAction<{ [key: string]:  MacroinvertebratesKeyData }>
  >;
  homeSamples: SampleListItem[]; 
  setHomeSamples: React.Dispatch<React.SetStateAction<SampleListItem[]>>;
};

export const ApiContext = createContext<APIParsedContextType>({
  familyGroupsData: {},
  setFamilyGroupsData: () => { },
  ordersData: {},
  setOrdersData: () => { },
  fFGData: {},
  setFFGData: () => { },
  specialAttributeData: {},
  setSpecialAttributeData: () => { },
  macroinvertebratesKeyData: { },
  setMacroinvertebratesKeyData: () => { },
  homeSamples: [],
  setHomeSamples: () => {}
});

export const ApiProvider = ({ children }: MyContextProps) => {
  const [familyGroupsData, setFamilyGroupsData] = useState<
    { [key: string]: FamilyGroupsData }
  >({});
  const [ordersData, setOrdersData] = useState<
    { [key: string]: OrdersData }
  >({});
  const [fFGData, setFFGData] = useState<
    { [key: string]: FfgData }
  >({});
  const [specialAttributeData, setSpecialAttributeData] = useState<
    { [key: string]: SpecialAttributesData }
  >({});
  const [macroinvertebratesKeyData, setMacroinvertebratesKeyData] = useState<
    { [key: string]: MacroinvertebratesKeyData }
  >({});
  const [homeSamples, setHomeSamples] = useState<SampleListItem[]>([]);

  return (
    <ApiContext.Provider
      value={{
        familyGroupsData,
        setFamilyGroupsData,
        ordersData,
        setOrdersData,
        fFGData,
        setFFGData,
        specialAttributeData,
        setSpecialAttributeData,
        macroinvertebratesKeyData,
        setMacroinvertebratesKeyData,
        homeSamples,
        setHomeSamples
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};