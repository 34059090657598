import {
  Flex,
  Center,
  Button,
  useToast,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { sampleCalculation } from "../../services/sample-calculation/sampleCalculation";
import { ApiContext } from "../../context/ApiContext";
import ajaxService from "../../services/ajaxService";
import { Header } from "../Layout/Header";
import YourInfo from "./YourInfo";
import SampleInformation from "./SampleInformation";
import StreamEnvInfo from "./StreamEnvInfo";
import KeyInput from "./AddOrganism/KeyInput";
import Modal from "./Modal";
import ShowToast from "./ShowToast";

const SampleSubmissionForm = () => {
  const { familyGroupsData, ordersData, fFGData, specialAttributeData } =
    useContext(ApiContext);
  const toast = useToast();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resId, setResId] = useState("");
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    if (
      !!data.CompSliderTotal &&
      data.CompSliderTotal !== 100 &&
      data.CompSliderTotal !== 0
    ) {
      ShowToast({
        isDesktop: isDesktop,
        toast: toast,
        title: "Invalid submission",
        description: "Stream composition sliders must add up to 100%",
        status: "error",
      });
      const slidersRef = document.getElementById("streamEnvInfo");
      if (slidersRef) {
        slidersRef.scrollIntoView({
          behavior: "auto",
        });
      }
      return;
    }
    if (!!!data.FamilyGroupsJson || data.FamilyGroupsJson.length === 0) {
      ShowToast({
        isDesktop: isDesktop,
        toast: toast,
        title: "Invalid submission",
        description: "Please enter at least one organism",
        status: "error",
      });
      return;
    }
    let organismArray = data.FamilyGroupsJson;
    let test = await sampleCalculation(
      organismArray,
      familyGroupsData,
      ordersData,
      fFGData,
      specialAttributeData
    );

    const Status = { Status: ["rechEwtXJGzRQeiMP"] };
    let finalData = { ...data, ...Status, ...test };
    finalData.FamilyGroupsJson = JSON.stringify(finalData.FamilyGroupsJson);

    ajaxService
      .post("/Samples", finalData)
      .then((res) => {
        setResId(res.id);
        setShowModal(true);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error(error);
        alert("something failed");
      });
  };

  return (
    <>
      <Header title="ENTER SAMPLE" />
      <Box style={{ maxWidth: "1440px", margin: "10px auto" }}>
        <FormProvider {...methods}>
          <Flex
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)(e);
            }}
            flexDirection="column"
            ml="40px"
            mr="40px"
            gap="sm"
          >
            <YourInfo />
            <SampleInformation />
            <StreamEnvInfo />
            <KeyInput />

            <Center m="md">
              <Button id="bottomOfTable" type="submit" variant="primary" isDisabled={isSubmitted}>
                Submit Sample
              </Button>
            </Center>
            {showModal && <Modal setShowModal={setShowModal} resId={resId} />}
          </Flex>
        </FormProvider>
      </Box>
    </>
  );
};
export default SampleSubmissionForm;
