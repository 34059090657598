import { Flex, Input, FormLabel, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { ValidationRule, useFormContext } from "react-hook-form";

interface TextInputProps {
  label: string;
  secondarylabel?: string;
  value: string;
  formType: string;
  isRequired?: boolean;
  hasPattern?: ValidationRule<RegExp>;
  isShort?: boolean;
}

const TextInput = ({
  label,
  secondarylabel,
  value,
  isRequired,
  hasPattern,
  formType,
  isShort
}: TextInputProps) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <FormControl isInvalid={!!errors[value]}>
      <Flex flexDirection="column" maxW={isShort ? "max-content" : ""}>
        <Flex
          flexDirection={{
            lg: "row",
            md: "column",
            base: "column",
            sm: "column"
          }}
        >
          <FormLabel htmlFor={value}>
            {label}
            {isRequired && (
              <span style={{ color: "red", padding: "5px", fontWeight: "500" }}>*</span>
            )}
          </FormLabel>
          {secondarylabel && <Text variant="secondarylabel">{secondarylabel}</Text>}
        </Flex>
        <Input
          border="1px solid"
          borderColor="gray_cool.400"
          fontFamily="font.body"
          fontSize="16px"
          _hover={{ borderColor: "gray_cool.600" }}
          type={formType}
          {...register(value, { required: isRequired, pattern: hasPattern })}
        />
        <FormErrorMessage>This field is required.</FormErrorMessage>
      </Flex>
    </FormControl>
  );
};

export default TextInput;
