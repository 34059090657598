import {
    Box,
    Text,
    Flex,
    Icon,
    Button,
    Card,
    CardBody,
    CardFooter,
    useBreakpointValue
} from "@chakra-ui/react";
import { xIcon, fileIcon, houseIcon, arrowIcon } from "../../icons/Icons";

interface Props {
    setShowModal: any;
    resId: string;
}

type CardProps = {
    icon: any;
    text: string;
};

const MyCard = ({ icon, text }: CardProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    return (
        <Card
            w={{ lg: "200px" }}
            h={{ lg: "200px" }}
            cursor="pointer"
            overflow='hidden'
            boxShadow="0 0 0 1px rgb(203, 213, 224)"
            _hover={{
                transform: "scale(1.01)",
            }}>
            <Flex direction="column" justify="center" align="center">
                {isDesktop &&
                    <CardBody>
                        <Icon as={icon} />
                    </CardBody>
                }
                <CardFooter>
                    <Text variant="optionCard">{text}</Text>
                </CardFooter>
            </Flex>
        </Card>
    );
};

const Modal = ({ setShowModal, resId }: Props) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="rgba(0, 34, 85, 0.75)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
        >
            <Box background="white" p="md" borderRadius="md" boxShadow="md">
                {isDesktop && <Flex justifyContent="flex-end">
                    <Button onClick={() => setShowModal(false)}>
                        <Icon as={xIcon}></Icon>
                    </Button>
                </Flex>}
                <Text
                    variant="submissionSectionTitle"
                    ml={{ lg: "md" }}
                >
                    THANKS FOR YOUR SUBMISSION
                </Text>
                <Flex gap="sm" flexDir={{ lg: "row", base: "column" }} m="md">
                    <a href="/"> <MyCard
                        icon={houseIcon}
                        text="Go Home"
                    /></a>
                    <a href="/EnterSample"><MyCard
                        icon={arrowIcon}
                        text="Submit Again"
                    /></a>
                    <a href={`report/${resId}`}> <MyCard
                        icon={fileIcon}
                        text="View Report"
                    /></a>
                </Flex>
            </Box>
        </Box>
    );
};

export default Modal;
