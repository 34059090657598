import {
  FormControl,
  FormLabel,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useState, useRef } from "react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { ApiContext } from "../../../context/ApiContext";
import OrganismInputValidation from "./OrganismInputValidation";
interface AutoCompleteProps {
  handleOrganismChange: (organism: string) => void;
  handleOrganismRecordIdChange: (organismRecordId: string) => void;
  handleCountChange: (valueAsString: string) => void;
  handleAddOrganism: () => void;
  value: string;
}

const AutoCompleteInstance: React.FC<AutoCompleteProps> = (props) => {
  const organisms = useContext(ApiContext).familyGroupsData;
  const [count, setCount] = useState("");
  const [organism, setOrganism] = useState("");
  const countInputRef = useRef<HTMLInputElement>(null);
  const organismInputRef = useRef<HTMLInputElement>(null);
  const colorMode = useColorModeValue("white", "primary.gray.brand");
  const [orgId, setOrgId] = useState("");

  const handleOrganismChange = (e: string) => {
    const organism = e.split(" (")[0];
    setOrgId(e.split(" (")[1].split(")")[0]);
    setOrganism(organism);
    props.handleOrganismChange(organism);
    props.handleOrganismRecordIdChange(e.split(" (")[1].split(")")[0]);
    if (countInputRef.current) {
      countInputRef.current.focus();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganism(e.target.value);
  };

  const handleOrganismCountChange = (valueAsString: string) => {
    const cleanedValue = valueAsString.replace(/\D/g, "");
    setCount(cleanedValue);
    props.handleCountChange(cleanedValue);
  };

  const handleAddOrganism = () => {
    props.handleAddOrganism();
    setCount("");
    setOrganism("");
    setOrgId("");
  };

  return (
    <Flex
      align="flex-start"
      justify="space-between"
      m={{ lg: "40px", base: "40px 0" }}
      flexDirection={{ base: "column", lg: "row", md: "row", sm: "column" }}
      gap="md"
    >
      <FormControl w="100%" fontFamily="font.body">
        <FormLabel>Start typing an organism</FormLabel>
        <AutoComplete
          openOnFocus
          onChange={handleOrganismChange}
          value={organism}
          key={count}
        >
          <AutoCompleteInput
            variant="filled"
            value={organism}
            onChange={handleInputChange}
            bg="primary.gray.brand"
            ref={organismInputRef}
          />
          <AutoCompleteList bg={colorMode}>
            {Object.values(organisms).map((organism, cid) => (
              <AutoCompleteItem
                key={`option-${cid}`}
                value={
                  organism.familyName +
                  " - " +
                  organism.commonName +
                  " (" +
                  organism.id +
                  ")"
                }
                textTransform="capitalize"
              >
                {organism.familyName + " - " + organism.commonName}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </AutoComplete>
      </FormControl>
      <OrganismInputValidation
        value={count}
        countChange={handleOrganismCountChange}
        handleAddOrganism={handleAddOrganism}
        organismNumber={count}
        orgId={orgId}
        inputRef={organismInputRef}
      />
    </Flex>
  );
};

export default AutoCompleteInstance;
