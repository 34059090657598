import { createContext, useState } from "react";
import FullReport from "../components/FullReport/FullReport";
import { SingleSampleResponse, ContentChunkModel } from "../models/ContextParsedDataStructure";

type ReportContextType = {
  report: SingleSampleResponse | null;
  setReport: React.Dispatch<React.SetStateAction<SingleSampleResponse | null>>;
  contentChunks: ContentChunkModel[] | null; 
  setContentChunks: React.Dispatch<React.SetStateAction< ContentChunkModel[] | null>>;
};

export const ReportContext = createContext<ReportContextType>({
  report: null,
  setReport: () => {},
  contentChunks: null,
  setContentChunks: () => {},
});

export const ReportProvider = () => {
  const [report, setReport] = useState<SingleSampleResponse | null>(null);
  const [contentChunks, setContentChunks] = useState< ContentChunkModel[]| null>(null);
  return (
    <ReportContext.Provider
      value={{
        report,
        setReport,
        contentChunks, 
        setContentChunks, 
      }}
    >
      <FullReport />
    </ReportContext.Provider>
  );
};
