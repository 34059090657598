import {
  Box,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  Icon,
  useBreakpointValue,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { BizLogoWhite } from "../../icons/Icons";
import { Link, useNavigate } from "react-router-dom";
import SimpleSidebar from "./SimpleSideBar";
import { BizStreamLogoTextW } from "../../icons/Icons";

export const Nav = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
  };

  return (
    <Box as="section">
      <Box as="nav" boxShadow={useColorModeValue("sm", "sm-dark")}>
        <Container py={{ base: "4", lg: "5" }} maxW="auto">
          <HStack justifyContent="space-between">
            {isDesktop ? (
              <Flex w="100%" align="center">
                <Flex
                  justify="flex-start"
                  pl="sm"
                  flex="1"
                  gap="xsm"
                  align="center"
                  cursor="pointer"
                  onClick={() => goHome()}
                >
                  <BizStreamLogoTextW />
                  <Text
                    color="primary.gray.brand"
                    fontSize="30px"
                    fontFamily="font.heading"
                    fontWeight="400"
                    lineHeight="35px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                  >
                    THE
                    <span style={{ fontWeight: "700" }}> RAIL </span>
                    PROJECT
                  </Text>
                </Flex>
                <Flex justify="flex-end" align="center" flex="1" pr="sm">
                  <ButtonGroup
                    variant="link"
                    spacing="35px"
                  >
                    <Link to="/">
                      <Text variant="navLinks">HOME</Text>
                    </Link>
                    <Link to="/Reports">
                      <Text variant="navLinks">REPORTS</Text>
                    </Link>
                    <Link to="/EnterSample">
                      <Text variant="navLinks">ENTER SAMPLE</Text>
                    </Link>
                    <Link to="/Contact">
                      <Text variant="navLinks">CONTACT</Text>
                    </Link>
                  </ButtonGroup>
                </Flex>
              </Flex>
            ) : (
              <Flex display="contents">
                <Flex
                  cursor="pointer"
                  onClick={() => goHome()}
                  justifyContent="flex-start"
                >
                  <Icon as={BizLogoWhite} w="35px" h="35px" />
                </Flex>
                <Flex mb="1%" justifyContent="flex-end">
                  <SimpleSidebar children={[]} />
                </Flex>
              </Flex>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
};
