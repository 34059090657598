import {
    Box,
    Text,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  } from "@chakra-ui/react";
import AutoComplete from "./AutoComplete";
import OrganismsSelector from "./OrganismsSelector";

interface Props { 
    setOrganism: any; 
    setOrganismNumber: any; 
    handleAddOrganism: any; 
    setOrganismRecordId: any; 
    organism: any; 
}

export default function KeyInputDesktop({setOrganism, setOrganismNumber, handleAddOrganism, setOrganismRecordId, organism}: Props) { 
    return ( 
        <>
          <Box
            borderRadius="lg"
            outline="1px solid"
            outlineColor="gray_neutral.100"
          >
            <Tabs isFitted variant="enclosed">
              <TabList>
                <Tab
                  bg="gray_neutral.100"
                  _selected={{ bg: "white", color: "primary.blue.brand" }}
                >
                  <Text variant="submissionSectionTitle">BY NAME</Text>
                </Tab>
                <Tab
                  bg="gray_neutral.100"
                  _selected={{ bg: "white", color: "primary.blue.brand" }}
                >
                  <Text variant="submissionSectionTitle">BY SELECTOR</Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <AutoComplete
                    handleOrganismChange={setOrganism}
                    handleCountChange={setOrganismNumber}
                    handleAddOrganism={handleAddOrganism}
                    handleOrganismRecordIdChange={setOrganismRecordId}
                    value={organism}
                  ></AutoComplete>
                </TabPanel>

                <TabPanel>
                  <OrganismsSelector
                    handleOrganismChange={setOrganism}
                    handleCountChange={setOrganismNumber}
                    handleAddOrganism={handleAddOrganism}
                    handleOrganismRecordIdChange={setOrganismRecordId}
                  ></OrganismsSelector>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </>
    )
}