import IndependentSlider from "./Components/IndependentSlider";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Text, Box, Icon, Flex, Button } from "@chakra-ui/react";
import { BlueLine } from "../../icons/Icons";

interface SliderProps {
  label: string;
  value: number;
  setStateValue: (value: number) => void;
  dbValue: string;
}

export default function Sliders() {
  const { setValue } = useFormContext();
  const [sliderError, setSliderError] = useState(false);
  const [boulderSlider, setboulderSlider] = useState(0);
  const [cobbleSlider, setCobbleSlider] = useState(0);
  const [gravelSlider, setgravelSlider] = useState(0);
  const [sandSlider, setSandSlider] = useState(0);
  const [bedrockSlider, setBedrockSlider] = useState(0);
  const [pooledSlider, setPooledSlider] = useState(0);
  const [shadedSlider, setShadedSlider] = useState(0);
  const [compTotal, setCompTotal] = useState(0);
  const sliders: SliderProps[] = [
    {
      label: "Boulder (3'<) Composition",
      value: boulderSlider,
      setStateValue: setboulderSlider,
      dbValue: "BoulderComp",
    },
    {
      label: "Cobble (6\" - 3') Composition",
      value: cobbleSlider,
      setStateValue: setCobbleSlider,
      dbValue: "CobbleComp",
    },
    {
      label: 'Gravel (1" - 6") Composition',
      value: gravelSlider,
      setStateValue: setgravelSlider,
      dbValue: "GravelComp",
    },
    {
      label: 'Sand (1">) Composition',
      value: sandSlider,
      setStateValue: setSandSlider,
      dbValue: "SandComp",
    },
    {
      label: "Bedrock Composition",
      value: bedrockSlider,
      setStateValue: setBedrockSlider,
      dbValue: "BedrockComp",
    },
  ];

  const handleCombinedSliderChange = (
    value: number,
    setStateValue: (value: number) => void,
    dbValue: string
  ) => {
    const total = sliders
      .filter((slider) => slider.setStateValue !== setStateValue)
      .reduce((acc, curr) => acc + curr.value, 0);
    if (total + value <= 100) {
      setStateValue(value);
      setValue(dbValue, value.toString());

      setCompTotal(total + value);
      setValue("CompSliderTotal", total + value);

      if (total + value === 100 || total + value === 0) setSliderError(false);
      else setSliderError(true);
    }
  };

  const handleIndependentSliderChange = (
    value: number,
    setSelectedValue: any,
    dbValue: string
  ) => {
    setSelectedValue(value);
    setValue(dbValue, value.toString());
  };

  const handleReset = () => {
    setSliderError(false);
    sliders.forEach((slider) => {
      slider.setStateValue(0);
      setValue(slider.dbValue, 0);

      setCompTotal(0);
      setValue("CompSliderTotal", 0);
    });
  };

  return (
    <Flex gap="30px" flexDirection="column" mb="md" w="100%">
      <Flex
        w="100%"
        direction={{ lg: "row", base: "column" }}
        align="flex-start"
        justify="space-between"
        gap={{ base: "5px", lg: "" }}
      >
        <Text
          color={sliderError ? "red" : "primary.blue.600"}
          fontWeight="600"
          w="100%"
        >
          Composition must add to 100%
        </Text>
        <Flex
          direction={{ lg: "column", base: "row" }}
          gap="5px"
          align="flex-end"
          w="100%"
          justify={{ base: "space-between" }}
        >
          <Text color="primary.blue.600" fontWeight="600">
            Current total: {compTotal}%
          </Text>
          <Button onClick={handleReset} variant="primarySmall">
            Reset
          </Button>
        </Flex>
      </Flex>

      <Flex gap="50px" flexDirection="column">
        {sliders.map(({ label, value, setStateValue, dbValue }) => (
          <IndependentSlider
            key={label}
            suffix="%"
            label={label}
            dbValue={dbValue}
            selectedValue={value}
            setSelectedValue={setStateValue}
            handleSliderChange={handleCombinedSliderChange}
          />
        ))}
      </Flex>
      <Box flex={1} mt="30px" mb="30px">
        <Icon as={BlueLine}></Icon>
      </Box>
      <Flex gap="50px" flexDirection="column">
        <IndependentSlider
          suffix="%"
          label="Sections are pooled"
          dbValue="Pooled"
          selectedValue={pooledSlider}
          setSelectedValue={setPooledSlider}
          handleSliderChange={handleIndependentSliderChange}
        />
        <IndependentSlider
          suffix="%"
          label="Sections are shaded"
          dbValue="Shaded"
          selectedValue={shadedSlider}
          setSelectedValue={setShadedSlider}
          handleSliderChange={handleIndependentSliderChange}
        />
      </Flex>
    </Flex>
  );
}
