import { SampleList } from "../HomePage/SampleList";
import { Header } from "../Layout/Header";
import {
  Flex,
  Box,
  Button,
  Text,
  Icon,
  Select,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchAllSamplesTable } from "../../services/fetchAllData";
import { SampleListItem } from "../../models/ContextParsedDataStructure";
import { downArrow, upArrow } from "../../icons/Icons";

export default function AllSamplesPage() {
  const [hasMoreSamples, setHasMoreSamples] = useState(true);
  const [loading, setLoading] = useState(false);
  const [samples, setSamples] = useState<SampleListItem[]>([]);
  const [page, setPage] = useState(1);
  const [sortOption, setSortOption] = useState("SampleDate");
  const [sortOrder, setSortOrder] = useState("desc");
  const topOfPageRef = document.getElementById("header");
  const pageSize = 75;
  const isDesktop = useBreakpointValue({ lg: true, base: false });

  const handleShowMore = () => {
    setLoading(true);
    setPage(page + 1);
  };

  const handleScroll = () => {
    if (topOfPageRef) topOfPageRef.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchAllSamplesTable(page, sortOption, sortOrder);
      if (res) {
        setSamples(res);
        setLoading(false);
        if (res.length < pageSize * page) {
          setHasMoreSamples(false);
        }
      }
    };

    fetchData();
  }, [page, sortOption, sortOrder]);

  return (
    <>
      <Header title="REPORTS" />
      <Box maxW="1440px" w="full" m="50px auto">
        <Flex mr="40px" ml="40px" direction="column">
          <Flex
            mb="40px"
            gap={{ base: "40px" }}
            flexDir={{ lg: "row", base: "column" }}
            justify={{ lg: "space-between", base: "center" }}
            align={{ base: "center" }}
          >
            <Button variant="primary">
              <a href="EnterSample">Enter Your Sample</a>
            </Button>

            <Flex gap="10px" flexDir="row" alignItems="center">
              {isDesktop && <Text sx={{ textWrap: "nowrap" }}>Sort by:</Text>}
              <Select
                w="250px"
                value={`${sortOption}-${sortOrder}`}
                onChange={(e) => {
                  const [option, order] = e.target.value.split("-");
                  setSortOption(option);
                  setSortOrder(order);
                }}
              >
                <option value="SampleDate-desc"><Text>Date (newest - oldest)</Text></option>
                <option value="SampleDate-asc"><Text>Date (oldest - newest)</Text></option>
                <option value="ReportId-desc">
                <Text>Sample # (highest - lowest)</Text>
                </option>
                <option value="ReportId-asc"><Text>Sample # (lowest - highest)</Text></option>
              </Select>
            </Flex>
          </Flex>

          {!!samples.length && <SampleList samples={samples} />}
          <Flex
            mt="40px"
            w="100%"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="20px"
          >
            {hasMoreSamples && (
              <Button onClick={handleShowMore}>
                <Text variant="breadcrumbLink" pr="10px">
                  Show More Samples
                </Text>
                <Icon as={downArrow} />
              </Button>
            )}
            {!hasMoreSamples && samples.length > 15 && (
              <Button onClick={handleScroll}>
                <Text variant="breadcrumbLink" pr="10px">
                  Go back to top
                </Text>
                <Icon as={upArrow} />
              </Button>
            )}
            {loading ? <Spinner /> : <Box pt="24px"></Box>}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
