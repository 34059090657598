import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import {
  formatMetric,
  isAutotrophicCalc,
  isFallWinterCalc,
  isFPOMInTransportCalc,
  hasStableSubstrateCalc,
} from "../../services/sample-calculation/calculationService";

const SEAandDMCalculations = () => {
  const { report } = useContext(ReportContext);
  const data = report;

  return (
    <Box>
      <TableContainer
        mt="10px"
        borderRadius="8px 8px 0 0"
        border="1px"
        borderColor="gray_cool.300"
        width="100%"
      >
        <Table variant="report">
          <Thead>
            <Tr>
              <Td>ECOSYSTEM ATTRIBUTE RESULTS</Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>A/H Index</Td>
              <Td textAlign="center" fontStyle="italic">
                <Text
                  display="inline"
                  variant={
                    isAutotrophicCalc(data?.prIndex)
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Autotrophic
                </Text>
                {" >"} 0.75 {"> "}
                <Text
                  display="inline"
                  variant={
                    isAutotrophicCalc(data?.prIndex)
                      ? "sectionBody"
                      : "standOut"
                  }
                >
                  Hetrotrophic
                </Text>
              </Td>

              <Td textAlign="right">{formatMetric(data?.prIndex)}</Td>
            </Tr>
            <Tr>
              <Td>CPOM/FPOM Index</Td>
              <Td>
                <Text
                  display="inline"
                  variant={
                    isFallWinterCalc(data?.cpomFpomIndex)
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Fall-Winter Shredder Stream
                </Text>
                {" >"} 0.50 {"> "}
                <Text
                  display="inline"
                  variant={
                    isFallWinterCalc(data?.cpomFpomIndex)
                      ? "sectionBody"
                      : "standOut"
                  }
                >
                  Spring-Summer Shredder Stream
                </Text>
              </Td>
              <Td textAlign="right">{formatMetric(data?.cpomFpomIndex)}</Td>
            </Tr>
            <Tr>
              <Td>TFPOM/BFPOM Index</Td>
              <Td textAlign="center">
                <Text
                  display="inline"
                  variant={
                    isFPOMInTransportCalc(data?.tfpomFpomIndex)
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  FPOM in Transport
                </Text>
                {" >"} 0.50 {"> "}
                <Text
                  display="inline"
                  variant={
                    isFPOMInTransportCalc(data?.tfpomFpomIndex)
                      ? "sectionBody"
                      : "standOut"
                  }
                >
                  FPOM in Storage
                </Text>
              </Td>
              <Td textAlign="right">{formatMetric(data?.tfpomFpomIndex)}</Td>
            </Tr>
            <Tr>
              <Td>Channel Stability Index</Td>
              <Td textAlign="center">
                <Text
                  display="inline"
                  variant={
                    hasStableSubstrateCalc(data?.channelStabilityIndex)
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Stable Channel Substrate
                </Text>
                {" >"} 0.50 {"> "}
                <Text
                  display="inline"
                  variant={
                    hasStableSubstrateCalc(data?.channelStabilityIndex)
                      ? "sectionBody"
                      : "standOut"
                  }
                >
                  Unstable Channel Substrate
                </Text>
              </Td>
              <Td textAlign="right">
                {formatMetric(data?.channelStabilityIndex)}
              </Td>
            </Tr>
            <Tr>
              <Td>Top-down Predator Control Index</Td>
              <Td textAlign="center">
                <Text
                  display="inline"
                  variant={
                    data?.topDownPredatorControlIndex !== undefined &&
                    data?.topDownPredatorControlIndex <= 0.1
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Unhealthy Balance
                </Text>
                {" <"} 0.1 {"< "}
                <Text
                  display="inline"
                  variant={
                    data?.topDownPredatorControlIndex !== undefined &&
                    data?.topDownPredatorControlIndex < 0.2 &&
                    data?.topDownPredatorControlIndex > 0.1
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Healthy Balance
                </Text>
                {" <"} 0.2 {"< "}
                <Text
                  display="inline"
                  variant={
                    data?.topDownPredatorControlIndex !== undefined &&
                    data?.topDownPredatorControlIndex >= 0.2
                      ? "standOut"
                      : "sectionBody"
                  }
                >
                  Unhealthy Balance
                </Text>
              </Td>
              <Td textAlign="right">
                {formatMetric(data?.topDownPredatorControlIndex)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default SEAandDMCalculations;
