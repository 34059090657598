import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Td,
  TableContainer,
  Box,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import { ApiContext } from "../../context/ApiContext";
import { TaxonomySingle } from "../../models/TaxonomyList";

const TaxonomyCatalog = () => {
  const { report } = useContext(ReportContext);
  const { familyGroupsData, fFGData } = useContext(ApiContext);
  const data = report;

  const parsedTaxonomyData = () => {
    const data = report;
    const taxonomyList = data?.familyGroupsJson;
    if (taxonomyList) {
      return JSON.parse(taxonomyList);
    }
    return [];
  };

  return (
    <Box>
      <Text variant="sectionTitle">TAXONOMY CATALOG</Text>
      <br></br>
      <TableContainer
        borderRadius={"8px 8px 0 0"}
        border="1px"
        borderColor={"gray_cool.300"}
      >
        <Table variant="report">
          <Thead bg={"primary.blue.brand"}>
            <Tr>
              <Td>ORGANISM</Td>
              <Td>COUNT</Td>
              <Td>FFG</Td>
              <Td textAlign="center">FTV</Td>
              <Td>CALCULATED FTV</Td>
            </Tr>
          </Thead>
          <Tbody>
            {parsedTaxonomyData().map((organism: TaxonomySingle) => {
              let organismData = familyGroupsData[organism.familyGroupId];
              let organismName = organismData?.familyName;
              let organismFFG = fFGData[organismData?.ffg]?.name;
              let organismFTV = organismData?.ftv;
              let organismCalculatedFTV = organism.count * organismFTV;
              return (
                <Tr key={organism.familyGroupId}>
                  <Td>{organismName}</Td>
                  <Td textAlign="right" paddingRight="50px">
                    {organism.count}
                  </Td>
                  <Td>{organismFFG}</Td>
                  <Td textAlign="center">{organismFTV}</Td>
                  <Td textAlign="right" px="50px">
                    {organismCalculatedFTV.toString()}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr
              fontWeight="bold"
              color="primary.blue.brand"
              borderTop={"1px"}
              borderTopColor={"secondary.blue_accent.brand"}
            >
              <Td>Totals:</Td>
              <Td paddingLeft="30px" paddingRight="50px" textAlign="right">
                {data?.organismsCount}
              </Td>
              <Td></Td>
              <Td></Td>
              <Td paddingLeft="45px" paddingRight="50px" textAlign="right">
                {data?.totalFTV}
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <br></br>
    </Box>
  );
};
export default TaxonomyCatalog;
