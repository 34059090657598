interface OptionType {
  value: string;
  label: string;
}
interface TableType {
  title: string;
  data: OptionType[];
}

const CountOptions: TableType = {
  title: "Counts",
  data: [
    { value: "organismsCount", label: "Organisms" },
    { value: "scrapersCount", label: "Scrapers" },
    { value: "fCollectorsCount", label: "F Collectors" },
    { value: "gCollectorsCount", label: "G Collectors" },
    { value: "predatorsCount", label: "Predators" },
    { value: "shreddersCount", label: "Shredders" },
  ],
};

const PercentOptions: TableType = {
  title: "Percent",
  data: [
    { value: "chironomidaePercent", label: "Chironomidae" },
    { value: "clingersPercent", label: "Clingers" },
    { value: "dominancePercent", label: "Dominance" },
    { value: "eptAbundancePercent", label: "EPT Abundance" },
    {
      value: "netSpinningTrichopteraPercent",
      label: "net Spinning Trichoptera",
    },
    { value: "sensitivePercent", label: "Sensitive" },
    { value: "tolerantPercent", label: "Tolerant" },
  ],
};

const IndexOptions: TableType = {
  title: "Index",
  data: [
    { value: "channelStabilityIndex", label: "Channel Stability" },
    { value: "cpomFpomIndex", label: "CPOM/FPOM" },
    { value: "hilsenhoffBioticIndex", label: "Hilsenhoff Biotic" },
    { value: "prIndex", label: "PR" },
    { value: "streamConditionIndex", label: "Stream Cond." },
    { value: "tfpomFpomIndex", label: "TFPOM/FPOM" },
    { value: "topDownPredatorControlIndex", label: "TD Pred Control" },
    { value: "waterQualityIndex", label: "WQI" },
    { value: "sdi", label: "SDI" },
  ],
};

const ScoreOptions: TableType = {
  title: "Score",
  data: [
    { value: "dominanceScore", label: "Dominance" },
    { value: "eptAbundanceScore", label: "EPT Abundance" },
    { value: "eptTaxaScore", label: "EPT Taxa" },
    { value: "hilsenhoffBioticIndexScore", label: "Hilsenhoff Biotic Index" },
    { value: "netSpinningTrichopteraScore", label: "Net Spinning Trichoptera" },
    { value: "tolerantScore", label: "Tolerant" },
    { value: "totalTaxaScore", label: "Total Taxa" },
    { value: "totalFTV", label: "Total FTV" },
  ],
};

const TaxaOptions: TableType = {
  title: "Taxa",
  data: [
    { value: "cOETTaxa", label: "COET" },
    { value: "dipteraTaxa", label: "Diptera" },
    { value: "ePTTaxa", label: "EPT" },
    { value: "ephemeropteraTaxa", label: "Ephemeroptera" },
    { value: "longLivedTaxa", label: "Long-Lived" },
    { value: "odonataTaxa", label: "Odonata" },
    { value: "plecopteraTaxa", label: "Plecoptera" },
    { value: "trichopteraTaxa", label: "Trichoptera" },
    { value: "totalTaxa", label: "Total Taxa" },
  ],
};

const Bools: TableType = {
  title: "Bools",
  data: [
    { value: "hasStableSubstrate", label: "Has Stable Substrate" },
    { value: "isAutotrophic", label: "Is Autotrophic" },
    { value: "isFPOMInTransport", label: "Is FPOM in Transport" },
    { value: "isFallWinter", label: "Is Fall/Winter" },
    { value: "isPredatorPreyHealthy", label: "Is Predator/Prey Healthy" },
    { value: "isShortLifeCycle", label: "Is Short Life Cycle" },
  ],
};

export const TableOptionsArray: TableType[] = [
  CountOptions,
  PercentOptions,
  IndexOptions,
  ScoreOptions,
  TaxaOptions,
  Bools,
];
