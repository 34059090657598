import ajaxService from "../../services/ajaxService";
import { useContext, useState, useEffect } from "react";
import { sampleCalculation } from "../../services/sample-calculation/sampleCalculation";
import { ApiContext } from "../../context/ApiContext";
import { Header } from "../Layout/Header";
import RecalcTable from "./RecalcTable";
import BeforeTable from "./BeforeTable";
import { Flex, Box, Text, Button, Spinner, Heading } from "@chakra-ui/react";
import { TableOptionsArray } from "./TableOptions";
import { fetchRecalcSamples } from "../../services/fetchAllData";

const RecalculatePage = () => {
  const [samples, setSamples] = useState<any[]>([]);
  const [newSamples, setNewSamples] = useState<any[]>([]);
  const [buttonClick, setButtonClick] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { familyGroupsData, ordersData, fFGData, specialAttributeData } =
    useContext(ApiContext);

  useEffect(() => {
    fetchRecalcSamples().then((res) => setSamples(res)).catch(console.error);
  }, []);

  async function recalc() {
    const newSamplePromises = samples.map(async (sample: any) => {
      const orgArray = JSON.parse(sample.fields.familyGroupsJson);
      let newCalcs = await sampleCalculation(
        orgArray,
        familyGroupsData,
        ordersData,
        fFGData,
        specialAttributeData
      );
      return ajaxService.patch(`/Samples/${sample.id}`, newCalcs);
    });

    await Promise.all(newSamplePromises);
    fetchRecalcSamples().then((res) => setNewSamples(res));
  }

  setTimeout(() => {
    setIsButtonDisabled(false);
  }, 1500);

  const handleButtonClick = async () => {
    setButtonClick(true);
    setNewSamples([]);
    recalc();
  };

  return (
    <>
      <Header title="TOP SECRET" />
      <Flex direction="column" align="center" mt="50px" gap="md">
        <Heading>Perform a recalculation on the existing reports</Heading>
        <Button
          variant="primary"
          onClick={handleButtonClick}
          isDisabled={isButtonDisabled}
        >
          Recalculate
        </Button>
        {buttonClick && !!!newSamples.length && <Spinner size="lg"></Spinner>}
      </Flex>
      <Flex direction="column" align="left" m="md" gap="md">
        {!buttonClick && !!samples && (
          <>
            {TableOptionsArray.map((options, i) => {
              return (
                <BeforeTable
                  key={i}
                  title={options.title}
                  samples={samples}
                  options={options.data}
                ></BeforeTable>
              );
            })}
          </>
        )}
        {!!newSamples.length && !!samples.length && (
          <>
            <Flex direction="column" gap="sm" mt="md" ml="md" align="flex-start">
              <Flex dir="row" align="flex-end">
                <Box w="30px" h="30px" mr="10px" bg="rgb(204, 241, 205)"></Box>
                <Text fontWeight="bold">Value did not change</Text>
              </Flex>
              <Flex direction="column">
                <Flex dir="row" align="flex-end">
                  <Box w="30px" h="30px" mr="10px" bg="rgb(250,199,199)"></Box>
                  <Text fontWeight="bold">Value did change</Text>
                </Flex>
                <Text ml="md">Click on data to view changes</Text>
              </Flex>
            </Flex>
            {TableOptionsArray.map((options, i) => {
              return (
                <RecalcTable
                  key={i}
                  title={options.title}
                  samples={samples}
                  newSamples={newSamples}
                  options={options.data}
                ></RecalcTable>
              );
            })}
          </>
        )}
      </Flex>
    </>
  );
};

export default RecalculatePage;
