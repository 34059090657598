export type Range = {
  name: string;
  description: string;
  color: string;
  min: number; 
  max: number;
};


export const StreamIndexRanges : Range[] = [
  {
    name: "Poor",
    description: "",
    color: "#E85428",
    min: 0,
    max: 50,
  },
  {
    name: "Marginal",
    description: "",
    color: "#EE9135",
    min: 50,
    max: 65,
  },
  {
    name: "Suboptimal",
    description: "",
    color: "#D9FD5D",
    min: 65,
    max: 80,
  },
  {
    name: "Optimal",
    description: "",
    color: "#6BC350",
    min: 80,
    max: 100,
  },
]


export const WaterQualityIndexRanges: Range[] = [
  {
    name: "Excellent",
    description: "Organic pollution unlikely",
    color: "#6BC350",
    min: 0,
    max: 3.75,
  },
  {
    name: "Very Good",
    description: "Slight organic pollution possible",
    color: "#A2E057",
    min: 3.75,
    max: 4.25,
  },
  {
    name: "Good",
    description: "Some organic pollution probable",
    color: "#D9FD5D",
    min: 4.25,
    max: 5,
  },
  {
    name: "Fair",
    description: "Fairly substantial pollution likely",
    color: "#E4C749",
    min: 5,
    max: 5.75,
  },
  {
    name: "Fairly Poor",
    description: "Substantial pollution likely",
    color: "#EE9135",
    min: 5.75,
    max: 6.5,
  },
  {
    name: "Poor",
    description: "Very substaintial pollution likely",
    color: "#EB732F",
    min: 6.5,
    max: 7.25,
  },
  {
    name: "Very Poor",
    description: "Severe organic pollution likely",
    color: "#E85428",
    min: 7.25,
    max: 10,
  },
];

