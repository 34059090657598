import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Th,
  Heading,
} from "@chakra-ui/react";
import { useState } from "react";

interface Props {
  title: string;
  samples: any[];
  newSamples: any[];
  options: any[];
}

function checkDiff(a: any, b: any) {
  return a === b ? "rgb(204, 241, 205)" : "rgb(250,199,199)";
}

export default function RecalcTable({
  title,
  samples,
  newSamples,
  options,
}: Props) {
  const [clicked, setClicked] = useState(false);
  function handleClick() {
    setClicked(clicked ? false : true);
  }
  return (
    <TableContainer>
      <Heading m="sm">{title}</Heading>
      <Table variant="test">
        <Thead>
          <Tr>
            <Th>Sample</Th>
            {options.map((option, i) => (
              <Th key={i}>{option.label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {samples.map((sample, i) => {
            const newSample = newSamples[i];
            return (
              <Tr key={i}>
                <Td>{sample.fields.reportId}</Td>
                {options.map((option, j) => {
                  return (
                    <Td
                      cursor="pointer"
                      onClick={handleClick}
                      key={j}
                      bg={checkDiff(
                        sample.fields[option.value],
                        newSample.fields[option.value]
                      )}
                    >
                      {clicked
                        ? sample.fields[option.value]?.toString()
                        : newSample.fields[option.value]?.toString()}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}