export function getAttributeById(data: { [key: string]: any }, LookupCode: string): string {
    for (const key in data) { 
        if (data.hasOwnProperty(key) && data[key].name === LookupCode) { 
            return key; 
        }
    }
    throw new Error(); 
}

export function getAttributeByCommonName(data: { [key: string]: any }, Name: string): string[] {
    let idList: string[] = [];
    for (const key in data) { 
        if (data.hasOwnProperty(key) && data[key].commonName === Name) { 
            idList.push(key); 
        }
    }
    if (idList.length > 0) { 
        return idList;
    }
    throw new Error(); 
}