import { ReportIcon } from "../../icons/Icons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
  HStack,
  Icon,
  Box,
  Flex,
  Text,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { QualityIndicator } from "../QualityIndicator";
import { useNavigate } from "react-router-dom";
import {
  StreamIndexRanges,
  WaterQualityIndexRanges,
} from "../../constants/constants";
import {
  getColorBasedOnValueFromRange,
  getRangeNameBasedOnValueFromRange,
  getWQIRangeColorBasedOnValue,
  getWQIRangeNameBasedOnValue,
} from "../../services/rangeService";
import { SampleListItem } from "../../models/ContextParsedDataStructure";

interface HomePageProps {
  samples: SampleListItem[];
}

export const SampleList = ({ samples }: HomePageProps) => {
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ lg: true, base: false });

  const labels =
    useBreakpointValue({
      base: ["REPORT", "DETAILS"],
      md: ["REPORT", "DETAILS", "STREAM", "WQI"],
      lg: ["REPORT", "DETAILS", "STREAM", "STREAM CONDITION", "WQI"],
      xl: [
        "REPORT",
        "DATE",
        "NAME",
        "STREAM",
        "STREAM CONDITION",
        "WATER QUALITY",
        "ORGANISMS",
      ],
    }) ?? [];

  const goToSingleReport = (id: string) => {
    navigate(`/report/${id}`);
  };

  return (
    !!samples && (
      <TableContainer>
        <Table variant="samples">
          <Thead>
            <Tr>
              {labels.map((ele, i) => (
                <Th key={i}>{ele}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {samples.map((ele, i) => {
              return (
                <Tr h="60px" key={i}>
                  <Td>
                    <HStack fontSize="16px" top="45px" right="15px">
                      <Flex
                        align="center"
                        cursor="pointer"
                        onClick={() => goToSingleReport(ele.recId)}
                      >
                        <Icon as={ReportIcon} />
                        <Box ml="8px">
                          <Text fontWeight="600" color="primary.blue.600">
                            {isDesktop ? "Sample #" : ""}{ele.reportId}
                          </Text>
                        </Box>
                      </Flex>
                    </HStack>
                  </Td>
                  {labels.length !== 7 && (
                    <Td>
                      <span style={{ fontWeight: "bold" }}>
                        {ele.sampleDate}
                      </span>
                      <br />
                      {ele.userName}
                    </Td>
                  )}
                  {labels.length === 7 && (
                    <>
                      <Td>{ele.sampleDate}</Td>
                      <Td>{ele.userName}</Td>
                    </>
                  )}
                  {labels.length > 2 && <Td>{ele.streamName}</Td>}
                  {labels.length > 4 && (
                    <Td>
                      <QualityIndicator
                        tagLabel={getRangeNameBasedOnValueFromRange(
                          StreamIndexRanges,
                          parseFloat(ele.streamConditionIndex as string)
                        )}
                        colorScheme={getColorBasedOnValueFromRange(
                          StreamIndexRanges,
                          parseFloat(ele.streamConditionIndex as string)
                        )}
                        value={parseFloat(ele.streamConditionIndex).toFixed(3)}
                      />
                    </Td>
                  )}
                  {labels.length > 2 && (
                    <Td>
                      <QualityIndicator
                        tagLabel={getWQIRangeNameBasedOnValue(
                          WaterQualityIndexRanges,
                          parseFloat(ele.waterQualityIndex as string)
                        )}
                        colorScheme={getWQIRangeColorBasedOnValue(
                          parseFloat(ele.waterQualityIndex as string)
                        )}
                        value={parseFloat(ele.waterQualityIndex).toFixed(3)}
                      />
                    </Td>
                  )}
                  {labels.length > 5 && <Td>{ele.organismsCount}</Td>}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    )
  );
};
