import {
  Text,
  Flex,
  Box,
  Link,
  Icon,
} from "@chakra-ui/react";
import { Header } from "../Layout/Header";
import { linkIcon, emailIcon } from "../../icons/Icons";

const ContactPage = () => {
  return (
    <>
      <Header title="CONTACT AND RESOURCES" />
      <Box maxW="1440px" m="50px auto" w="full">
        <Flex
          flexDirection="column"
          gap={{ sm: "80px", base: "50px" }}
          m={{ sm: "0 40px 200px 40px", base: "0 40px 100px 40px" }}
        >
          <Text variant="paragraph" fontSize={{ sm: "25px", base: "16px" }}>
            If you have any questions and/or concerns please do not hesitate to
            contact us.
          </Text>

          <Flex flexDir="column" gap="50px">
            <ListElement
              title="Send us an email:"
              link="mailto:piccarke@gvsu.edu"
              linkText="piccarke@gvsu.edu"
              icon={emailIcon}
            />
            <ListElement
              title="Go to our website:"
              link="https://sites.google.com/a/apsfalcons.net/rail/home"
              linkText="sites.google.com/rail/home"
              icon={linkIcon}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
export default ContactPage;

interface listElementProps {
  title: string;
  link: string;
  linkText: string;
  icon: any;
}

const ListElement = ({ title, link, linkText, icon }: listElementProps) => {
  const fontSz = { sm: "22px", base: "16px" };
  return (
    <Flex flexDir="row" gap="30px" align="center">
      <Link href={link}>
        <Icon as={icon} />
      </Link>

      <Flex flexDir={{ md: "row", base: "column" }} align="baseline" gap="10px">
        <Text variant="paragraph" fontSize={fontSz}>
          {title}
        </Text>
        <Link href={link} fontSize={fontSz} color="003D78" target="_blank">
          {linkText}
        </Link>
      </Flex>
    </Flex>
  );
};
