import { Box, Icon, Flex, Text } from "@chakra-ui/react";
import { BizStreamLogoBlue, BlueLine } from "../../icons/Icons";
import { BizStreamLogoTextB } from "../../icons/Icons";
import { css } from "@emotion/react";

const PageFooter = () => {
  return (
    <Box
      css={css`
        @media print {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 0px 60px;
          background-color: white;
          align-items: center;
          display: none;
    
          
        }
    }
    `}
    >
      <Box my="12px">
        <Icon w="100%" as={BlueLine}></Icon>
      </Box>
      <Flex justify="space-between">
        <BizStreamLogoBlue />
        <BizStreamLogoTextB />

        <Text
          sx={{
            "@media screen and (max-width: 768px)": {
              fontSize: "12px",
            },
          }}
          fontSize="16px"
          lineHeight={"25px"}
          fontWeight="400"
          fontFamily={"font.body"}
          whiteSpace="nowrap"
        >
          Copyright © 2023 BizStream. All Rights Reserved.
        </Text>
      </Flex>
    </Box>
  );
};
export default PageFooter;
