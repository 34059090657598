import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import AutoComplete from "./AutoComplete";
import OrganismsSelector from "./OrganismsSelector";

interface Props {
  setOrganism: any;
  setOrganismNumber: any;
  handleAddOrganism: any;
  setOrganismRecordId: any;
  organism: any;
}

export default function KeyInputMobile({
  setOrganism,
  setOrganismNumber,
  handleAddOrganism,
  setOrganismRecordId,
  organism,
}: Props) {
  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box
              as={Text}
              flex="1"
              textAlign="left"
              variant="submissionSectionTitle"
            >
              BY NAME
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb="350px">
            {/* TODO: If there is no padding, the list sometimes gets cutoff by the other tab */}
            <AutoComplete
              handleOrganismChange={setOrganism}
              handleCountChange={setOrganismNumber}
              handleAddOrganism={handleAddOrganism}
              handleOrganismRecordIdChange={setOrganismRecordId}
              value={organism}
            ></AutoComplete>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box
              as={Text}
              flex="1"
              textAlign="left"
              variant="submissionSectionTitle"
            >
              BY SELECTOR
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <OrganismsSelector
              handleOrganismChange={setOrganism}
              handleCountChange={setOrganismNumber}
              handleAddOrganism={handleAddOrganism}
              handleOrganismRecordIdChange={setOrganismRecordId}
            ></OrganismsSelector>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
