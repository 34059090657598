import { Box, Text, HStack } from "@chakra-ui/react";
import { BizStreamLogoTextB } from "../../icons/Icons";

export const Footer = () => {
  return (
    <Box
      id="footer"
      color="black"
      textAlign={["left", "left", "center"]}
      pt="40px"
      bg="#EDECE6"
      pb="45px"
      paddingLeft="10%"
      paddingRight="10%"
    >
      <Text fontSize="16px" mb="15px" fontFamily="font.body">
        Copyright © 2023 BizStream Apps. All Rights Reserved.
      </Text>
      <Text fontSize="16px" mb="37px" fontFamily="font.body">
        Built by BizStream{" "}
      </Text>
      <HStack justify="center">
        <BizStreamLogoTextB/>
      </HStack>
    </Box>
  );
};
