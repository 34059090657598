import React, { ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import PageFooter from "../ReportParts/PageFooter";

type PageProps = {
  children: ReactNode;
};

const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Box
      w="1063px"
      h="auto"
      bg="white"
      pt="50px"
      pb="60px"
      px="80px"
      fontFamily="font.body"
      className="print"
      sx={{
        "@media print": {
          width: "1063px",
          height: "auto",
          breakInside: "avoid",
        },
        "@media screen and (max-width: 992px)": {
          width: "100%",
          height: "auto",
          padding: "5%",
        },
      }}
    >
      {children}
      {/* <PageFooter /> */}
    </Box>
  );
};

export default Page;
