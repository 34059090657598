import React from "react";
import { Box, Flex, Text, Heading, HStack, Icon } from "@chakra-ui/react";
import { BlueLine } from "../../icons/Icons";
import Keith from "../../assets/images/Keith.png";

interface SampleNumberProps {
  sampleNumber: number;
}

interface PageTitleProps {
  pageTitle: string;
}

const PageHeader = ({
  sampleNumber,
  pageTitle,
}: SampleNumberProps & PageTitleProps) => {
  return (
    <Flex
      flexDirection={"column"}
      sx={{
        pageBreakBefore: "always",
      }}
    >
      <HStack
        mb="30px"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Box flexDirection={"row"} display="flex" gap="3%">
          <img src={Keith} alt="Keith" />
          <Flex justify={"space-between"} flexDirection="column">
            <Heading className="page-header-title" width="max-content">
              {pageTitle}
            </Heading>
            <style>
              {`
                @media screen and (max-width: 768px) {
                .page-header-title {
                  font-size: 1.4rem;
                }
              }

              @media print and (max-width: 768px) {
                .page-header-title {
                  font-size: 2.25rem;
                }
              }
              `}
            </style>
            <Text
              fontFamily={"font.body"}
              fontSize="32px"
              lineHeight={"40px"}
              fontWeight="700"
              color={"primary.blue.600"}
              whiteSpace={"nowrap"}
            >
              SAMPLE {sampleNumber}
            </Text>
          </Flex>
        </Box>
        <style>
          {`
            @media screen and (max-width: 768px) {
              .hide-box {
                display: none;
              }
            }
          `}
        </style>
        <Box
          width="200px"
          textAlign={"right"}
          justifyContent={"right"}
          className="hide-box"
        >
          <Text
            pl="5%"
            fontSize="26px"
            fontFamily="font.heading"
            fontWeight="400"
            lineHeight="30px"
            letterSpacing="1.3px"
            textTransform="uppercase"
            color="primary.blue.brand"
          >
            THE <span style={{ fontWeight: "700" }}> RAIL </span> PROJECT
          </Text>
        </Box>
      </HStack>
      <Icon w="100%" as={BlueLine}></Icon>
    </Flex>
  );
};

export default PageHeader;
