import { Box, Flex, Text, SimpleGrid, Icon } from "@chakra-ui/react";
import { BlueLine } from "../../icons/Icons";
import { HeaderTitle } from "../../theme/prestyledComponents";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import { css } from "@emotion/react";

const GeneralReportInfo = () => {
  const { report } = useContext(ReportContext);
  const data = report;

  return (
    <>
      <Box>
        <SimpleGrid
          className="GeneralReportClass"
          columns={2}
          fontSize="15px"
          lineHeight={"21px"}
          pt="1%"
          sx={{
            "@media print": {
              "& > div": {
                flexDirection: "row !important",
              },
            },
            "@media screen and (max-width: 992px)": {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Flex direction={{ base: "column", md: "row" }}>
            <HeaderTitle title={"Name"} />
            <Text>{data?.userName}</Text>
          </Flex>
          <Flex direction={{ base: "column", md: "row" }}>
            <HeaderTitle title={"Date"} />
            <Text>{data?.sampleDate}</Text>
          </Flex>

          <Flex direction={{ base: "column", md: "row" }}>
            <HeaderTitle title={"Stream Name"} />
            <Text>{data?.streamName}</Text>
          </Flex>

          <Flex direction={{ base: "column", md: "row" }}>
            <HeaderTitle title={"GPS Coordinates"} />
            <Text>{data?.coordinates}</Text>
          </Flex>
        </SimpleGrid>
        <Box my="10px">
          <Icon w="100%" as={BlueLine}></Icon>
        </Box>
      </Box>
    </>
  );
};

export default GeneralReportInfo;
