const ROOT_API_URL = process.env.REACT_APP_ROOT_API_URL;
const ajaxService = {
  async get(url, options = {}) {
    const fullUrl = `${ROOT_API_URL}${url}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      ...options,
    };
    const response = await fetch(fullUrl, requestOptions);
    if (response.status === 204) { 
      return "No content"; 
    } 
    return response.json();
  },
  async post(url, data, options = {}) {
    const fullUrl = `${ROOT_API_URL}${url}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      body: JSON.stringify(data),
      ...options,
    };
    const response = await fetch(fullUrl, requestOptions);
    return response.json();
  },
  async patch(url, data, options = {}) {
    const fullUrl = `${ROOT_API_URL}${url}`;
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      body: JSON.stringify(data),
      ...options,
    };
    const response = await fetch(fullUrl, requestOptions);
    return response.json();
  },
};
export default ajaxService;
