import {
  Flex,
  Text,
  Image,
  FormControl,
  Box,
  useRadio,
  useRadioGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  SimpleGrid,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState, useRef } from "react";
import OrganismInputValidation from "./OrganismInputValidation";
import { ApiContext } from "../../../context/ApiContext";
import {
  FamilyGroupsData,
  MacroOptionData,
  MacroinvertebratesKeyData,
} from "../../../models/ContextParsedDataStructure";
import { InfoIcon } from "@chakra-ui/icons";
import { prevPageIcon, rightArrow } from "../../../icons/Icons";
import OptionCard from "./OptionCard";

interface OrganismsSelectorProps {
  handleOrganismChange: (e: string) => void;
  handleCountChange: (valueAsString: string) => void;
  handleAddOrganism: () => void;
  handleOrganismRecordIdChange: (e: string) => void;
}

function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} checked={props.isChecked} />
      <OptionCard
        {...checkbox}
        text={props.buttonText}
        image={props.image}
        isChecked={props.isChecked}
      />
    </Box>
  );
}

function OrganismsSelector(props: OrganismsSelectorProps) {
  const [firstStepId, setFirstStepId] = useState<string>("");
  const [currentID, setCurrentID] = useState<string>("");
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [currentOptions, setCurrentOptions] = useState<MacroOptionData[]>([]);
  const [familyGroups, setFamilyGroups] = useState<FamilyGroupsData[]>([]);
  const [displayCountInput, setDisplayCountInput] = useState<boolean>(false);
  const [count, setCount] = useState<string>("");
  const [pastID, setPastID] = useState<string[]>([]);
  const [image, setImage] = useState<string>("");
  const { macroinvertebratesKeyData } = useContext(ApiContext);
  const { familyGroupsData } = useContext(ApiContext);
  const [selected, setSelected] = useState("");
  const [lastStep, setLastStep] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<MacroinvertebratesKeyData[]>(
    []
  );
  const [orgId, setOrgId] = useState("");
  const firstQ = "How many jointed legs?";
  const organismInputRef = useRef<HTMLInputElement>(null);

  const handleOrganismChange = (orgId: string) => {
    props.handleOrganismRecordIdChange(orgId);
    setOrgId(orgId);

    const foundOrg = familyGroupsData[orgId];
    if (foundOrg) {
      props.handleOrganismChange(
        `${foundOrg.familyName} - ${foundOrg.commonName}`
      );
      organismInputRef?.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    } else {
      console.warn("error");
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    onChange: setSelected,
  });

  const group = getRootProps();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const firstStep = useMemo(() => {
    if (macroinvertebratesKeyData) {
      return Object.values(macroinvertebratesKeyData).find(
        (item) => item.question === firstQ
      );
    }
  }, [macroinvertebratesKeyData]);

  useEffect(() => {
    if (firstStep) {
      setCurrentID(firstStep.id);
      setCurrentQuestion(firstStep.question);
      setCurrentOptions(firstStep.options);
      setImage(firstStep.infoImage);
      setFirstStepId(firstStep.id);
    }
  }, [firstStep]);

  const setFinalSelectionState = (stepId: string) => {
    const step = macroinvertebratesKeyData[stepId];
    const theseFamilyGroups = step.familyGroups.map((famId) => {
      return familyGroupsData[famId];
    });

    setFamilyGroups(theseFamilyGroups);
    setLastStep(true);
    setCurrentID(step.id);
    setCurrentQuestion(step.question);
    setDisplayCountInput(true);
    setPastID([...pastID, currentID]);
    setImage("");
    addBreadcrumb(step);
  };

  const setDecisionStep = (stepId: string) => {
    const step = macroinvertebratesKeyData[stepId];
    setCurrentID(step.id);
    setCurrentQuestion(step.question);
    setCurrentOptions(step.options);
    setImage(step.infoImage || "");
    setPastID([...pastID, currentID]);
    addBreadcrumb(step);
  };

  const setID = (stepId: string) => {
    const step = macroinvertebratesKeyData[stepId];

    // this should never happen
    if (step === undefined) {
      // throw error
      console.warn("error");
      return;
    }

    // if we do not have an option, then we are in the final organizsm selection state
    if (step.options[0].optionText === null) {
      setFinalSelectionState(stepId);
      return;
    }

    // show current step
    setDecisionStep(stepId);
  };

  const handleCountChange = (valueAsString: string) => {
    const cleanedValue = valueAsString.replace(/\D/g, "");
    setCount(cleanedValue);
    props.handleCountChange(cleanedValue);
  };

  const handleAddOrganism = () => {
    props.handleAddOrganism();
    setCount("");
    setSelected("");
  };

  const reset = () => {
    const firstStep = macroinvertebratesKeyData[firstStepId];
    setCurrentID(firstStep.id);
    setCurrentQuestion(firstStep.question);
    setCurrentOptions(firstStep.options);
    setImage(firstStep.infoImage);
    setLastStep(false);
    setBreadcrumbs([]);
    setSelected("");
    setPastID([...pastID, currentID]);
    setDisplayCountInput(false);
    setCount("");
    setFamilyGroups([]);
    const resetRef = document.getElementById("addOrganisms");
    if (resetRef)
      resetRef.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
  };

  function addBreadcrumb(newBreadcrumb: MacroinvertebratesKeyData) {
    let updatedBreadcrumbs: MacroinvertebratesKeyData[] = [...breadcrumbs];
    updatedBreadcrumbs.push(newBreadcrumb);

    setBreadcrumbs(updatedBreadcrumbs);
  }

  function removeBreadcrumb(n: number) {
    for (let i = 0; i < n + 1; i++) {
      breadcrumbs.pop();
    }
  }

  const handleBreadcrumbClick = (step: MacroinvertebratesKeyData) => {
    if (lastStep) {
      setLastStep(false);
      setDisplayCountInput(false);
      setFamilyGroups([]);
      setOrgId("");
      setCount("");
    }
    const currentIndex = breadcrumbs.length - 1;
    const newIndex = breadcrumbs.findIndex((q) => q.id === step.id);
    removeBreadcrumb(currentIndex - newIndex);
    setDecisionStep(step.id);
  };

  const handlePreviousButtonClick = (step: MacroinvertebratesKeyData) => {
    breadcrumbs.length === 1 ? reset() : handleBreadcrumbClick(step);
  };

  return (
    <Flex direction="column">
      {isDesktop ? (
        <Box ml="md" mt="sm">
          <Breadcrumb separator={<Icon as={rightArrow} />}>
            <BreadcrumbItem>
              <BreadcrumbLink onClick={reset}>
                <Text variant="breadcrumbLink">Start</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumbs.map((q) => (
              <BreadcrumbItem key={q.id}>
                {q.id !== currentID && (
                  <BreadcrumbLink onClick={() => handleBreadcrumbClick(q)}>
                    <Text
                      variant={
                        q.id === currentID ? "optionCard" : "breadcrumbLink"
                      }
                    >
                      {q.questionBreadcrumbText}
                    </Text>
                  </BreadcrumbLink>
                )}
                {q.id === currentID && (
                  <Text variant="optionCard">{q.questionBreadcrumbText}</Text>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Box>
      ) : (
        <>
          {!!breadcrumbs.length ? (
            <Box mt="sm">
              <Button
                onClick={() =>
                  handlePreviousButtonClick(breadcrumbs[breadcrumbs.length - 2])
                }
              >
                <Icon as={prevPageIcon} />
                <Text variant="breadcrumbLink" pl="10px">
                  Go back
                </Text>
              </Button>
            </Box>
          ) : (
            <Box h="45px"></Box>
          )}
        </>
      )}
      <Flex
        m={{ lg: "25px 40px", base: "40px 0" }}
        align="flex-start"
        justify="space-between"
      >
        <Text variant="optionCard">{currentQuestion}</Text>
        {image && (
          <Popover>
            <PopoverTrigger>
              <Button>
                <InfoIcon></InfoIcon>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              w={{ lg: "700px", md: "700px", base: "350px" }}
              bg="black"
            >
              <PopoverArrow bg="black" />
              <PopoverCloseButton />
              <PopoverBody>
                <Image w="100%" src={image} alt="placeholder" />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
      {displayCountInput ? (
        <>
          <FormControl>
            <SimpleGrid
              columns={{ lg: 3, base: 1 }}
              spacing="sm"
              m={{ lg: "40px", base: "0 0 25px 0" }}
              {...group}
            >
              {familyGroups.map((value) => {
                const radio = getRadioProps({ value });
                return (
                  <RadioCard
                    key={value.familyName}
                    {...radio}
                    isChecked={value.familyName === selected}
                    onChange={() => {
                      handleOrganismChange(value.id);
                      setSelected(value.familyName);
                    }}
                    value={value.familyName}
                    image={value.image.url}
                    buttonText={value.familyName}
                  />
                );
              })}
            </SimpleGrid>
          </FormControl>
          <Flex
            m={{ lg: "40px", base: "40px 0" }}
            direction={{ lg: "row", base: "column" }}
            justify={{ lg: "space-between", base: "" }}
            align={{ lg: "flex-end", base: "flex-start" }}
            gap={{ lg: "md", base: "md" }}
          >
            <Box maxW="300px">
              <OrganismInputValidation
                value={count}
                countChange={handleCountChange}
                handleAddOrganism={handleAddOrganism}
                organismNumber={count}
                orgId={orgId}
                countRef={organismInputRef}
              />
            </Box>
            <Text variant="optionCard">
              Selected Organism:
              <span style={{ color: "rgb(37,104,204)" }}>{selected}</span>
            </Text>
            <Button onClick={reset} variant="primaryInversedSmall">
              Start over
            </Button>
          </Flex>
        </>
      ) : (
        <SimpleGrid
          columns={{ lg: 3, base: 1 }}
          spacing="sm"
          m={{ lg: "40px", base: "0 0 25px 0" }}
        >
          {currentOptions.map(
            (option, i) =>
              option.optionText &&
              option.optionLink && (
                <Box key={i}>
                  <OptionCard
                    text={option.optionText}
                    link={option.optionLink}
                    image={option.optionImageUrl}
                    handleCardClick={setID}
                  />
                </Box>
              )
          )}
        </SimpleGrid>
      )}
    </Flex>
  );
}

export default OrganismsSelector;
