import { Text, Box, Icon, Flex } from "@chakra-ui/react";
import { BlueLine } from "../../../icons/Icons";

interface Props {
  title: string;
}

export default function FormHeading({ title }: Props) {
  return (
    <Flex
      align="center"
      gap="sm"
      mt="md"
    >
      <Text variant="submissionSectionTitle">{title}</Text>
      <Box flex={1}>
        <Icon as={BlueLine}></Icon>
      </Box>
    </Flex>
  );
}
