import ajaxService from "./ajaxService";

export const fetchFamilyGroupsTable = async () => {
  return await ajaxService.get("/FamilyGroups");
};

export const fetchFFGTable = async () => {
  return await ajaxService.get("/FunctionalFeedingGroups");
};

export const fetchOrdersTable = async () => {
  return await ajaxService.get("/Orders");
};

export const fetchSpecialAttributeTable = async () => {
  return await ajaxService.get("/SpecialAttribute");
};

export const fetchMacroinvertebratesKeyTable = async () => {
  return await ajaxService.get("/MacroinvertebratesKey");
};

export const fetchRecalcSamples = async () => {
  return await ajaxService.get("/RecalcSamples");
};

export const fetchContentChunks = async () => {
  const response = await ajaxService.get("/ContentChunks");
  return response ? response.filter((a: any) => a.section === "instruction") : [];
};

export const fetchAllSamplesTable = async (
  pageNumber: number,
  sortOption: string,
  sortOrder: string
) => {
  let results = await ajaxService.get(
    `/Samples?page=${pageNumber}&sortBy=${sortOption}&sortOrder=${sortOrder}`
  );
  if (results.error || !results || results === "No content") {
    return null;
  }
  return results;
};

export const fetchHomeSamplesTable = async () => {
  const pageSize = 10; 
  return await ajaxService.get(`/Samples?pageSizeIn=${pageSize}`);
}; 

export const fetchSingleReport = async (reportId: string) => {
  let results = await ajaxService.get(`/Samples/${reportId}`);
  if (results.error || !results) {
    return null;
  }
  return results;
};
