import {
  Box,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Flex,
} from "@chakra-ui/react";
import { useContext } from "react";
import { ReportContext } from "../../context/ReportContext";
import { formatMetric } from "../../services/sample-calculation/calculationService";
export const WQMetricCalculationsTables = () => {
  const { report } = useContext(ReportContext);
  const data = report;

  return (
    <Box w="100%" h="100%">
      <Flex flexDirection="column" gap="10px">
        <TableContainer
          borderRadius="8px 8px 0 0"
          border="1px"
          borderColor="gray_cool.300"
        >
          <Table variant="report">
            <Thead>
              <Tr>
                <Td>RICHNESS</Td>
                <Td textAlign={"right"}>Total</Td>
                <Td textAlign={"right"}>Score</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Total Taxa</Td>
                <Td textAlign="right">{data?.totalTaxa}</Td>
                <Td textAlign="right">{formatMetric(data?.totalTaxaScore)}</Td>
              </Tr>
              <Tr>
                <Td>EPT Taxa</Td>
                <Td textAlign="right">{data?.eptTaxa}</Td>
                <Td textAlign="right">{formatMetric(data?.eptTaxaScore)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <TableContainer
          borderRadius="8px 8px 0 0"
          border="1px"
          borderColor="gray_cool.300"
        >
          <Table variant="report">
            <Thead>
              <Tr>
                <Td>TOLERANCE</Td>
                <Td textAlign={"right"}>Index</Td>
                <Td textAlign={"right"}>Score</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Hilsenhoff Biotic Index </Td>
                <Td textAlign="right">
                  {formatMetric(data?.hilsenhoffBioticIndex)}
                </Td>
                <Td textAlign="right">
                {formatMetric(data?.hilsenhoffBioticIndexScore)}
                </Td>
              </Tr>
              <Tr>
                <Td>% Tolerant</Td>
                <Td textAlign="right">{formatMetric(data?.tolerantPercent)}</Td>
                <Td textAlign="right">{formatMetric(data?.tolerantScore)}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer
          borderRadius="8px 8px 0 0"
          border="1px"
          borderColor="gray_cool.300"
        >
          <Table variant="report">
            <Thead>
              <Tr>
                <Td>COMPOSITION</Td>
                <Td textAlign={"right"}>Percentage</Td>
                <Td textAlign={"right"}>Score</Td>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>% EPT Abundance</Td>
                <Td textAlign="right">
                  {formatMetric(data?.eptAbundancePercent)}
                </Td>
                <Td textAlign="right">
                  {formatMetric(data?.eptAbundanceScore)}
                </Td>
              </Tr>
              <Tr>
                <Td>% Dominance</Td>
                <Td textAlign="right">
                  {formatMetric(data?.dominancePercent)}
                </Td>
                <Td textAlign="right">{formatMetric(data?.dominanceScore)}</Td>
              </Tr>
              <Tr>
                <Td>% Net-spinning Trichoptera</Td>
                <Td textAlign="right">
                  {formatMetric(data?.netSpinningTrichopteraPercent)}
                </Td>
                <Td textAlign="right">
                  {formatMetric(data?.netSpinningTrichopteraScore)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
};
