import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Th,
  Heading,
} from "@chakra-ui/react";

interface Props {
  title: string;
  samples: any[];
  options: any[];
}

export default function BeforeTable({ title, samples, options }: Props) {
  return (
    <TableContainer>
      <Heading m="sm">{title}</Heading>
      <Table variant="test">
        <Thead>
          <Tr>
            <Th>Sample</Th>
            {options.map((option, i) => (
              <Th key={i}>{option.label}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {samples.map((sample, i) => {
            return (
              <Tr key={i}>
                <Td>{sample.fields.reportId}</Td>
                {options.map((option, j) => (
                  <Td key={j}>{sample.fields[option.value]?.toString()}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
