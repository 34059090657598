import '@fontsource/work-sans/400.css'
import '@fontsource/barlow-condensed/400.css'
import '@fontsource/barlow-condensed/600.css'

const styles = {
  colors: {
    white: '#FFFFFF',
    gray_neutral: {
      50: '#F2F2F2',
      100: '#E5E5E5',
      200: '#CCCCCC',
      300: '#B2B2B2',
      400: '#999999',
      500: '#7F7F7F',
      600: '#666666',
      700: '#4C4C4C',
      800: '#333333',
      900: '#191919',
    },
    gray_cool: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
    primary: {
      blue: {
        50: '#F3FBFF',
        100: '#DFF4FF',
        200: '#C2E2FF',
        300: '#9BCFFF',
        400: '#68B3F9',
        500: '#3A81EC',
        600: '#2568CC',
        700: '#144FA7',
        brand: '#003078',
        900: '#002255',
      },
      gray: {
        brand: '#F2F8FD',
        100: '#DFEDFA',
        200: '#CADDEE',
        300: '#ADC6DC',
        400: '#89A7C2',
        500: '#6585A1',
        600: '#4D6B85',
        700: '#344E65',
        800: '#23384A',
        900: '#162735',
      },
    },
    secondary: {
      blue_accent: {
        50: '#EDF8FF',
        100: '#D9F1FF',
        200: '#BEE8FF',
        300: '#A0DDFF',
        brand: '#79CFFF',
        500: '#49B3EE',
        600: '#2C9BDA',
        700: '#1685C3',
        800: '#0B71AA',
        900: '#005483',
      },
      tan: {
        50: '#FAF9F4',
        brand: '#EDECE6',
        200: '#E2E1D9',
        300: '#DBDAD1',
        400: '#D0CFC4',
        500: '#C6C4B8',
        600: '#B9B7A9',
        700: '#A4A294',
        800: '#8E8D80',
        900: '#747368',
      },
    },
  },
  fonts: {
    font: {
      heading: 'Barlow Condensed',
      body: 'Work Sans',
      logo: 'Poppins'
    },
  },
  fontSizes: {
    sm: '0.75rem', 
    md: '1rem', 
    lg: '1.25rem', 
    header: '1.75rem', 
    heading: '5.625rem', 
  },
  px: {
    xxsm: '0.50rem', 
    xsm: '1.125rem', 
    sm: '1.563rem', 
    md: '2.5rem', 
    lg: '10rem', 
    xlg: '20rem', 
  },
  global: {
    '*': {
      borderWidth: '0.125rem', 
    },
  },
}
export default styles
