import {
    FamilyGroupsData,
    OrdersData,
    SpecialAttributesData,
} from "../../models/ContextParsedDataStructure"
import { OrganismList } from "../../models/OrganismListCount"

export function organismsCountCalc(data: OrganismList): number {
    let count = 0
    for (let ele of data) {
        count += parseInt(ele.count)
    }

    return count
}

export function totalFTVCalc(
    data: OrganismList,
    familyGroupData: { [key: string]: FamilyGroupsData }): number {
    let count = 0
    for (let ele of data) {
        let organism = familyGroupData[ele.familyGroupId]
        count += (organism.ftv * parseInt(ele.count))
    }

    return count
}

export function taxaCalc(
    data: OrganismList,
    familyGroupData: { [key: string]: FamilyGroupsData },
    orderData: { [key: string]: OrdersData },
    type: string[]): number {
    let count = 0
    for (let ele of data) {
        let organism = familyGroupData[ele.familyGroupId]
        let organismOrder = orderData[organism.order].name
        for (let ele2 of type) {
            if (organismOrder === ele2) {
                count++
            }
        }
    }

    return count
}

export function totalTaxaCalc(data: OrganismList): number {
    return data.length
}

export function ePTTaxa(
    ephemeropteraTaxa: number,
    plecopteraTaxa: number,
    trichopteraTaxa: number): number {
    return ephemeropteraTaxa + plecopteraTaxa + trichopteraTaxa
}

export function hilsenhoffBioticIndexCalc(totalFTV: number, organismCount: number) {
    const hbi = totalFTV / organismCount;
    return hbi;
}

export function percentCalc(
    data: OrganismList,
    id: string,
    organismCount: number,
    specialAttributeData: { [key: string]: SpecialAttributesData }): number {
    let count = 0
    let list = specialAttributeData[id]
    for (let ele of data) {
        let organismId = ele.familyGroupId
        if (list.familyGroups.includes(organismId)) {
            count += parseInt(ele.count)
        }
    }

    return count / organismCount * 100
}

export function ePTAbundancePercent(
    data: OrganismList,
    familyGroupData: { [key: string]: FamilyGroupsData },
    orderData: { [key: string]: OrdersData },
    organismCount: number,
    types: string[],
): number {
    let count = 0
    for (let ele of data) {
        let organism = familyGroupData[ele.familyGroupId]
        let organismOrder = orderData[organism.order].name
        for (let type of types) {
            if (organismOrder === type) {
                count += parseInt(ele.count)
            }
        }
    }

    return count / organismCount * 100
}

export function dominancePercentCalc(
    data: OrganismList,
    organismCount: number): number {
    let max = 0
    for (let ele of data) {
        let count = parseInt(ele.count)
        if (count > max) {
            max = count
        }
    }
    max = max / organismCount * 100

    return max;
}

export function scoreCalc(taxa: number, num: number,): number {
    let answer = Math.min(100, taxa / num * 100)

    return answer;
}

export function longLivedTaxaCalc(
    data: OrganismList,
    id: string,
    specialAttributeData: { [key: string]: SpecialAttributesData },
): number {
    let count = 0
    let list = specialAttributeData[id]
    for (let ele of data) {
        let organismId = ele.familyGroupId
        if (list.familyGroups.includes(organismId)) {
            count++
        }
    }

    return count
}

export function chronomidaePercent(
    data: OrganismList,
    idList: string[],
    organismCount: number,
): number {
    let count = 0
    for (let ele of data) {
        let organismId = ele.familyGroupId
        if (idList.includes(organismId)) {
            count += parseInt(ele.count)
        }
    }
    return count / organismCount * 100
}

export function fFGCountCalc(
    data: OrganismList,
    id: string,
    familyGroupData: { [key: string]: FamilyGroupsData },
): number {
    let count = 0
    for (let ele of data) {
        let organismId = ele.familyGroupId
        if (familyGroupData[organismId].ffg === id) {
            count += parseInt(ele.count)
        }
    }

    return count
}
//--------------------Index Calculations--------------------
export function pRIndexCalc(
    scrapperCount: number,
    shredderCount: number,
    fCollectorCount: number,
    gCollectorCount: number,
): number {
    let answer = scrapperCount / (shredderCount + fCollectorCount + gCollectorCount)
    answer = (answer === Infinity || Number.isNaN(answer)) ? scrapperCount : answer
    return answer
}


  export function cpomFpomIndexCalc(
    shredderCount: number,
    fCollectorCount: number,
    gCollectorCount: number,
  ): number {
    let answer = shredderCount / (gCollectorCount + fCollectorCount);
    answer = (answer === Infinity || Number.isNaN(answer)) ? shredderCount : answer;
    return answer;
  }
  



export function tfpomFpomIndexCalc(
    fCollectorCount: number,
    gCollectorCount: number,
): number {
    let answer = fCollectorCount / gCollectorCount
    answer = (answer === Infinity || Number.isNaN(answer)) ? fCollectorCount : answer
    return answer

}

export function channelStabilityIndexCalc(
    scrapperCount: number,
    shredderCount: number,
    fCollectorCount: number,
    gCollectorCount: number,
): number {
    let answer = parseFloat(((scrapperCount + fCollectorCount) / (shredderCount + gCollectorCount)).toFixed(3))
    answer = (answer === Infinity || Number.isNaN(answer)) ? scrapperCount + fCollectorCount : answer
    return answer
}

export function topDownPredatorControlIndexCalc(
    scrapperCount: number,
    shredderCount: number,
    fCollectorCount: number,
    gCollectorCount: number,
    predatorCount: number,
): number {
    let answer = parseFloat((predatorCount / (shredderCount + fCollectorCount + gCollectorCount + scrapperCount)).toFixed(3))
    answer = (answer === Infinity || Number.isNaN(answer)) ? predatorCount : answer
    return answer
}

export function isAutotrophicCalc(pRIndex?: number): boolean {
    return pRIndex !== undefined && pRIndex > 0.75;
  }
  
  export function isFallWinterCalc(cpomFpomIndex?: number): boolean {
    return cpomFpomIndex !== undefined && cpomFpomIndex > 0.5;
  }
  
  export function isFPOMInTransportCalc(tfpomFpomIndex?: number): boolean {
    return tfpomFpomIndex !== undefined && tfpomFpomIndex > 0.5;
  }
  
  export function hasStableSubstrateCalc(channelStabilityIndex?: number): boolean {
    return channelStabilityIndex !== undefined && channelStabilityIndex > 0.5;
  }
  
  export function isPredatorPreyHealthyCalc(
    topDownPredatorControlIndex?: number
  ): boolean {
    return (
      topDownPredatorControlIndex !== undefined &&
      topDownPredatorControlIndex > 0.1 &&
      topDownPredatorControlIndex < 0.2
    );
  }
  
  export function isShortLifeCycleCalc(
    topDownPredatorControlIndex?: number
  ): boolean {
    return (
      topDownPredatorControlIndex !== undefined &&
      topDownPredatorControlIndex > 0.15
    );
  }
  


export function waterQualityIndexCalc(totalFTV: number, organismCount: number): number {
    
    const result = totalFTV / organismCount;
    return result;
}

export function waterQualityRatingCalc(waterQualityIndex: number): string {
    if (waterQualityIndex <= 3.75) return "Excellent"
    if (waterQualityIndex > 3.75 && waterQualityIndex <= 4.25) return "Very Good"
    if (waterQualityIndex > 4.25 && waterQualityIndex <= 5) return "Good"
    if (waterQualityIndex > 5 && waterQualityIndex <= 5.75) return "Fair"
    if (waterQualityIndex > 5.75 && waterQualityIndex <= 6.5) return "Fairly Poor"
    if (waterQualityIndex > 6.5 && waterQualityIndex <= 7.25) return "Poor"
    if (waterQualityIndex > 7.25) return "Very Poor"
    return "???"
}



export function streamConditionIndexCalc(
    totalTaxaScore: number,
    ePTTaxaScore: number,
    hilsenhoffBioticIndexScore: number,
    tolerantScore: number,
    ePTAbundanceScore: number,
    dominanceScore: number,
): number {
    let answer = (totalTaxaScore +
        ePTTaxaScore +
        hilsenhoffBioticIndexScore +
        tolerantScore +
        ePTAbundanceScore +
        dominanceScore) / 6
    return answer;
}

export function SimpsonsDiversityIndexCalc(data: OrganismList, organismCount: number): number {
    const organismCountArray = data.map(ele => parseInt(ele.count));
    const N = organismCountArray.reduce((sum, count) => sum + count, 0);
    const summationMinusOne = organismCountArray.reduce((sum, count) => sum + (count * (count - 1)), 0);
    const bigNminusOne = (N * (N - 1))
    const sdi = bigNminusOne === 0 ? 0 : summationMinusOne / bigNminusOne; 

    return sdi;
}

export function SDInumeratorCalc(data: OrganismList, organismCount: number): number {
    const organismCountArray = data.map(ele => parseInt(ele.count));
    const summationMinusOne = organismCountArray.reduce((sum, count) => sum + (count * (count - 1)), 0);
    return summationMinusOne
}

export function SDIdenominatorCalc(data: OrganismList, organismCount: number) : number {
    const organismCountArray = data.map(ele => parseInt(ele.count));
    const N = organismCountArray.reduce((sum, count) => sum + count, 0);
    const bigNminusOne = (N * (N - 1))
    return bigNminusOne

}

export function formatMetric(value: String | number | undefined) {
    return formatDecimal(value, 3);
  }

export function formatDecimal(value: String | number | undefined, decimalPlaces: number) {
    const floatValue = parseFloat(String(value));
    const decimalPrecision = floatValue % 1 !== 0 ? decimalPlaces : 0;
    const formattedValue = floatValue.toFixed(Math.max(0, decimalPrecision));
    return parseFloat(formattedValue);
  }

  export function SDICategoryCalc(SimpsonsDiversityIndexCalc: number | undefined): string {
    if (SimpsonsDiversityIndexCalc !== undefined && SimpsonsDiversityIndexCalc > 0.65) return "Near Specialization";
    if (SimpsonsDiversityIndexCalc !== undefined && SimpsonsDiversityIndexCalc > 0.55 && SimpsonsDiversityIndexCalc <= 0.65) return "Low diversity";
    if (SimpsonsDiversityIndexCalc !== undefined && SimpsonsDiversityIndexCalc > 0.45 && SimpsonsDiversityIndexCalc <= 0.55) return "Medium diversity";
    if (SimpsonsDiversityIndexCalc !== undefined && SimpsonsDiversityIndexCalc < 0.45) return "High diversity";
    return "???";
}