import { Text, Box } from "@chakra-ui/react";

export const HeaderTitle = ({ title }) => {
  return (
    <Text
      mr="8px"
      color={"primary.blue.brand"}
      fontWeight="700"
      fontSize="15px"
    >
      {title + ":"}
    </Text>
  );
};

export const ReportCalcContainer = ({ contents, width }) => {
  return (
    <Box h="100%" w={width} textAlign={"center"} width="100">
      {contents}
    </Box>
  );
};

export const ReportCalcUp = ({ value }) => {
  return (
    <Box
      fontSize="22px"
      fontWeight="700"
      lineHeight="40px"
      width="100%"
      marginBottom="-6%"
    >
      {value}
    </Box>
  );
};
export const ReportCalcDown = ({ value }) => {
  return (
    <Box
      mt="3%"
      width="100%"
      fontSize={"12px"}
      fontWeight="600"
      lineHeight={"16px"}
    >
      {value}
    </Box>
  );
};
